import { BaseAudioRecorder } from './recorder.base.js';

export class MicrophoneAudioRecorder extends BaseAudioRecorder {
    constructor(userId, patientId, sessionId) {
        super(userId, patientId, sessionId);
    }

    async getMediaStream() {
        try {

            this.audioContext = new AudioContext();
            this.destination = this.audioContext.createMediaStreamDestination();

            await this.connectMicrophone()

            navigator.mediaDevices.addEventListener('devicechange', this.deviceChangeHandler);

            return this.destination.stream;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            throw error;
        }
    }
}
