<template>
  <v-container>
    <v-sheet class="d-flex flex-column justify-center align-center ml-5 mr-5 mt-4" color="#f9fbfd">

      <CreateSubscription class="mb-4" :details="true" />

      <v-card elevation="0" width="100%" class="d-flex flex-column rounded-border mb-2">
        <v-card-title class="left-align mb-5 mt-7 ml-5">User details</v-card-title>

        <div class="left-align mb-5 mt-7 ml-6 flex-grow-1">
          <v-text-field v-if="userObject" type="text" required placeholder="" v-model="userObject.name"
            class="ml-4 mr-4" color="accent" variant="underlined" density="compact"
            @update:modelValue="handleSaveDebounced" />
          <v-text-field v-if="userObject" type="text" required placeholder="" v-model="userObject.email"
            class="ml-4 mr-4" color="accent" variant="underlined" density="compact" :readonly="true" />
          <v-autocomplete v-if="userObject" label="Select specialty or type to search" variant="underlined"
            class="ml-4 mr-4" color="accent" v-model="userObject.speciality" @update:modelValue="handleSave"
            :items="['Anesthesiology', 'Cardiology', 'Dermatology', 'Emergency Medicine', 'Endocrinology', 'Family Medicine', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Hematology', 'Infectious Disease', 'Internal Medicine', 'Nephrology', 'Neurology', 'Nutritionist', 'Obstetrics and Gynecology', 'Oncology', 'Ophthalmology', 'Orthopedics', 'Otolaryngology', 'Pediatrics', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Psychiatry', 'Psychology', 'Pulmonology', 'Radiology', 'Rheumatology', 'Urology', 'Other',]">
          </v-autocomplete>

          <v-text-field @focusout="handleSave" v-if="userObject.speciality === 'Other'" label="Please specify"
            v-model="otherSpeciality" variant="underlined" class="mr-4 ml-4 mt-2"></v-text-field>


        </div>


        <v-dialog v-model="dialog" max-width="800" persistent>
          <template v-slot:activator="{ props: activatorProps }">
            <v-card-actions class="d-flex mt-auto ml-6 mb-1">
              <v-btn variant="text" @click="dialog = true" width="10%">
                Logout
              </v-btn>
            </v-card-actions>
          </template>

          <v-card prepend-icon="mdi-logout" title="Are you sure you want to logout?" style="max-width: 500px;">
            <v-card-text class="mt-4 mb-4">Mdhub will log you out from all open tabs. Please ensure you have completed
              your
              activities before
              proceeding."</v-card-text>
            <template v-slot:actions>
              <v-spacer></v-spacer>

              <v-btn elevation="0" width="auto" class="mdhub-btn" @click="dialog = false">
                Cancel
              </v-btn>

              <v-btn elevation="0" width="auto" @click="handleClick">
                Logout
              </v-btn>
            </template>
          </v-card>
        </v-dialog>
        <v-progress-circular v-if="isPendingSave" indeterminate color="primary"></v-progress-circular>
      </v-card>

      <UserSettings :userSettings="userSettings" :userId="userObject.id" v-if="userSettings" />

      <TimeCounter v-if="userObject && userObject.counter > 0" :numberOfSessions="userObject.counter" />
      <CurrentSubscription v-if="user" :userObject="user" />
      <SubscriptionProductsView v-show="false" />
    </v-sheet>
  </v-container>
</template>

<script>

import useLogout from '@/composables/useLogout'
import { resetAvailableFeatures } from '@/composables/getAvailableFeatures';
import { resetWhitelistedFeatures } from '@/composables/isWhitelistedAccount';
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import CreateSubscription from "@/views/user/CreateSubscription.vue";
import { ref, watchEffect } from 'vue';
import { debounce } from 'lodash';
import CurrentSubscription from './CurrentSubscription.vue';
import TimeCounter from '@/components/TimeCounter.vue'
import UserSettings from './UserSettings.vue';
import { trackEvent, LOGOUT } from '@/utilities/analyticsService';
import SubscriptionProductsView from "@/components/available-subscription-products/SubscriptionProductsView.vue";
import { getUserObject } from '@/composables/getUserObject'
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  components: {
    CreateSubscription,
    CurrentSubscription,
    TimeCounter,
    UserSettings,
    SubscriptionProductsView
  },
  data() {
    return {
      handleSaveDebounced: null,
      targetDate: new Date('2023-12-31T23:59:59')
    };
  },
  created() {
    this.handleSaveDebounced = debounce(this.handleSave, 1000); // 1 second delay
  },
  setup() {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()
    const userObject = ref({}); // Initialize userObject as a reactive reference

    const isPendingSave = ref(false)

    const userSettings = ref(null);
    const dialog = ref(false)

    const otherSpeciality = ref('');

    watchEffect(async () => {
      userObject.value = await getUserObject();
      if (userObject.value) {
        userSettings.value = userObject.value.settings || {}
      } else {
        userObject.value = {}; // Clear user object on logout
      }
    });


    const handleClick = async () => {
      dialog.value = false;
      trackEvent(LOGOUT, { userEmail: user.value.email, userId: user.value.uid })
      await logout()
      resetAvailableFeatures()
      resetWhitelistedFeatures()
      router.push({ name: 'Login' })
    }

    const handleSave = async () => {
      const functions = getFunctions();
      isPendingSave.value = true
      const speciality = userObject.value.speciality === 'Other' ? otherSpeciality.value : userObject.value.speciality;
      // const speciality = userObject.value.speciality;
      const name = userObject.value.name;

      // here we need to update the user speciality 
      const updateUser = httpsCallable(functions, 'updateUser');
      try {
        await updateUser({ speciality, name });
      } catch (error) {
        console.log('error in updating user', error);
      } finally {
        isPendingSave.value = false
      }
    }

    return { otherSpeciality, dialog, userSettings, handleClick, user, userObject, handleSave, isPendingSave }
  }
}

</script>

<style scoped>
.btn-solid-sm {
  margin-right: 2%;
}

.mdhub-card {
  width: 100%;
}

.left-align {
  text-align: left;
}

/* New responsive styles */
@media (max-width: 900px) {
  .mdhub-card {
    flex-direction: column;
  }

  .v-col {
    width: 100%;
  }
}
</style>