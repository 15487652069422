<template>
    <div class="d-flex flex-column">
        <v-text-field :disabled="!!item.cannotEdit" label="Title" v-model="item.title"></v-text-field>
        <v-textarea :disabled="!!item.cannotEdit" v-model="item.description" label="Description"></v-textarea>

        <p class="mt-3 mr-auto">Options</p>
        <span v-for="(_, i) in item.options">
            <v-text-field :disabled="!!item.cannotEdit" v-model="item.options[i]" :key="i" :label="`Item ${i + 1}`">
                <template v-slot:append-inner v-if="i > 0">
                    <v-icon @click="deleteOption(i)">mdi-delete</v-icon>
                </template>
            </v-text-field>
        </span>
        <p class="text-left" v-if="item.options?.length > 0">Define the options that require an additional comment when selected by the patient</p>
        <span v-for="(option, i) in item.options" :key="option + i">
            <v-checkbox v-if="!!option" class="mb-0" hide-details v-model="item.requireComment" density="compact"
                :value="item.options[i]" :label="option"></v-checkbox>

        </span>
        <v-btn v-if="!item.cannotEdit" width="200" class="mt-3" @click="item.options.push('')">Add item</v-btn>
        <v-checkbox :disabled="!!item.cannotEdit" class="mt-2" v-model="item.required" label="Required"></v-checkbox>
        <CardActions :item="item" @save="save" @remove="remove" @moveUp="moveUp"
            @moveDown="moveDown" @duplicate="duplicate"/>

    </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, watch } from 'vue';
import CardActions from './CardActions.vue';

const props = defineProps({
    item: Object,
})

onMounted(() => {
    if (!props.item.options) {
        props.item.options = ['']
    }
    if (!props.item.requireComment) {
        props.item.requireComment = []
    }
})

const emit = defineEmits(['save', 'remove', 'moveUp', 'moveDown', 'duplicate']);

const deleteOption = (index) => {
    props.item.options.splice(index, 1)
}


watch(props.item.options, (newVal) => {
    props.item.requireComment = props.item.requireComment.filter((comment) => {
        return newVal.includes(comment)
    })
})

const save = () => {
    emit('save')
}

const remove = () => {
    emit('remove', {
        id: props.item.id
    })
}

const moveUp = () => {
    emit('moveUp')
}

const moveDown = () => {
    emit('moveDown')
}

const duplicate = () => {
    emit('duplicate')
}
</script>