import { collection, onSnapshot, query, where } from "firebase/firestore";
import { ref, onMounted, onUnmounted, watchEffect } from 'vue';
import { projectFirestore } from '../firebase/config';
import getUser from "./getUser";
import { getUserObject } from '@/composables/getUserObject'

const { user } = getUser();
const conversations = ref([]);

let conversationsListener = null;
let interactionListeners = [];
let loading = ref(true);

const getAllConversations = async () => {
    console.log('Getting all conversations - listening');

    const userObject = await getUserObject();

    const conversationsQuery = query(
        collection(projectFirestore, 'conversations'),
        where("clinicId", "==", userObject?.clinicId)
    );

    conversationsListener = onSnapshot(conversationsQuery, (conversationSnap) => {
        conversations.value = [];
        if (conversationSnap.docs?.length === 0) {
            loading.value = false;
            conversations.value = [];
            return;
        }
        conversationSnap.docs.forEach((doc) => {
            const interactionData = doc.data();
            const interactionId = doc.id;
            const conversationsCollectionRef = collection(
                projectFirestore,
                'conversations',
                interactionId,
                'interactions'
            );

            const listener = onSnapshot(conversationsCollectionRef, (snapshot) => {
                const mappedConversations = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));

                const existingIndex = conversations.value.findIndex(conv => conv.id === interactionId);
                if (existingIndex !== -1) {
                    conversations.value[existingIndex] = {
                        id: interactionId,
                        conversationDetails: interactionData,
                        interaction: mappedConversations
                    };
                } else {
                    conversations.value.push({
                        id: interactionId,
                        conversationDetails: interactionData,
                        interaction: mappedConversations
                    });
                }
                loading.value = false;
            }, error => {
                loading.value = false;
                console.log('Received error while listening for the snapshot from "conversations" collection:', error);
            });

            interactionListeners.push(listener);
        });
    }, error => {
        console.log('Received error while listening for the conversations:', error);
    });
};

watchEffect(async () => {
    await getAllConversations();
});

onUnmounted(() => {
    if (conversationsListener) conversationsListener();
    interactionListeners.forEach(listener => listener());
});

export default {
    clinicConversations: conversations,
    loading
};