<template>
    <div>
        <v-navigation-drawer v-model="drawer" temporary location="right" :width="900" :persistent="true">
            <div class="recording-drawer">

                <Head />

                <Body />
            </div>

        </v-navigation-drawer>

        <v-overlay :model-value="overlay" :persistent="true" class="align-center justify-center spinner-overlay">
            <div class="d-flex flex-column align-center">
                <div class="d-flex flex-column align-center" v-if="uploadingAudio">

                    <v-progress-circular :model-value="Math.round(progress)" color="white" :size="100" :width="15">
                        <template v-slot:default> <span style="color:white">{{ Math.round(progress) }}%</span>
                        </template>
                    </v-progress-circular>
                    <h3 class="font-weight-bold mt-4 px-10 text-center" style="color:white;">Do not close or refresh the
                        window while audio is
                        uploading
                        or it will be lost.

                    </h3>

                </div>

                <v-progress-circular v-else color="white" indeterminate :size="100" :width="15"></v-progress-circular>
            </div>

        </v-overlay>

        <v-dialog v-model="idleDialog" width="400" persistent>
            <v-card class="d-flex flex-column align-start pa-5">
                <h5>Are you still recording?</h5>
                <p class="text-caption">You've been recording for over 2 hours. Please click "Continue" now, or the
                    recording
                    will
                    stop automatically in 2 minutes.</p>
                <v-progress-circular class="align-self-center mb-5" :model-value="progressCountdown" :size="100"
                    :width="15">
                    <template v-slot:default>
                        <h5 class="mb-0"> {{ formattedTime }}</h5>
                    </template>
                </v-progress-circular>


                <div class="d-flex align-self-end">
                    <v-btn elevation="0" @click="triggerFinish">
                        <span style="text-transform: none;">Finish Recording</span>
                    </v-btn>
                    <v-btn class="ml-2" color="bittersweet" elevation="0" @click="idleDialogContinue">
                        <span style="color:white; text-transform: none;">Continue</span>
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>

</template>

<script setup>
import { onMounted, onBeforeUnmount, watch, computed } from 'vue';
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import Head from './drawer/Head.vue';
import Body from './drawer/Body.vue';
import { trackEvent, RECORDER_TRIED_REFRESH, RECORDER_REACHED_TWO_HOURS, RECORDER_CONTINUED_AFTER_TWO_HOURS, RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_TIMEOUT, RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_USER_ACTION } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import { sendNotification } from '@/composables/useNotifications';

const { user } = getUser();

const store = useRecorderStore();
const { countdown, idleDialog, idleClosed, drawer, overlay, recording, sessionId, patientId, uploadingAudio, progress, elapsedTime } = storeToRefs(store)
const { setIdleDialog, setIdleClosed, setCountdown, setCountDownFinished } = store



onMounted(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
})

onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
})

const handleBeforeUnload = (e) => {
    if (recording.value || overlay.value) {
        trackEvent(RECORDER_TRIED_REFRESH, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value });
        e.preventDefault();
    }
}


watch(elapsedTime, (val) => {
    if (val === 10800 && recording.value && !idleClosed.value) {
        setIdleDialog(true)
        startCountDown()
        sendNotification({
            title: 'mdhub recording alert',
            body: 'Your mdhub recoding is about to finish automatically.'
        })
        trackEvent(RECORDER_REACHED_TWO_HOURS, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value });
    }
})

const idleDialogContinue = () => {
    setIdleDialog(false)
    setIdleClosed(true)
    clearInterval(countdownTimer)
    trackEvent(RECORDER_CONTINUED_AFTER_TWO_HOURS, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value });

}

const triggerFinish = () => {
    clearInterval(countdownTimer)
    setCountDownFinished(true)
    trackEvent(RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_USER_ACTION, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value });
}

let countdownTimer = null
const startCountDown = () => {

    countdownTimer = setInterval(() => {
        if (countdown.value > 0) setCountdown(countdown.value - 1)
        else {
            clearInterval(countdownTimer)
            setCountDownFinished(true)
            trackEvent(RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_TIMEOUT, { userId: user.value.uid, userEmail: user.value.email, patientId: patientId.value, sessionId: sessionId.value });
        }
    }, 1000)
}

const formattedTime = computed(() => {
    const minutes = Math.floor(countdown.value / 60);
    const seconds = countdown.value % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
})

const progressCountdown = computed(() => {
    return Math.round(120 - countdown.value)
})

</script>

<style scoped>
.recording-drawer {
    height: calc(100vh - 64px);
}
</style>
