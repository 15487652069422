<template>
    <div>
        <v-btn class="action-button" elevation="0" height="50" @click="toggle">
            <v-icon color="bittersweet" prepend-icon dense>{{ paused ? 'mdi-play' :
                'mdi-pause'
                }}</v-icon>
            <span class="ml-2 bittersweet-button">{{ paused ? 'Resume Recording' : 'Pause Recording' }}</span>
        </v-btn>
        <v-btn class="ml-3 action-button" elevation="0" color="bittersweet" height="50" @click="finishRecording"
            :loading="overlay">
            <v-icon size="large" color="white">mdi-tray-arrow-up</v-icon>
            <span class="ml-3 white--button"> Finish Recording</span>
        </v-btn>
    </div>
</template>

<script setup>
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import { defineProps, watch } from 'vue'
import getUser from '@/composables/getUser'
import { getFunctions, httpsCallable } from "firebase/functions";
import { trackEvent, AUDIO_UPLOADED, RECORDER_UPDATE_SESSION_DATA, RECORDER_STOP_RECORDING_ERROR_IN_COMPONENT, RECORDER_SUBMIT_AUDIO_ERROR } from '@/utilities/analyticsService';
import { useRouter } from 'vue-router';

const store = useRecorderStore();
const { countDownFinished, paused, patientId, sessionId, overlay } = storeToRefs(store)

const { finishRecordingAndGetBlob, uploadAudio, pauseRecording, resumeRecording, getBlob, clear, setOverlay, toggleDrawer, setDownloadAudioDialog, setUploadingAudio } = store

const { user } = getUser();
const router = useRouter();

const props = defineProps({
    selectedTemplate: Object,
    selectedQuestionnaire: Object,
    additionalNotes: String,
    followup: String
})

const toggle = () => {
    if (paused.value) {
        resumeRecording()
    } else {
        pauseRecording()
    }
}

const finishRecording = async () => {
    const fixedBlob = await finishRecordingAndGetBlob()

    await submitAudio(fixedBlob)
}

const submitAudio = async (blob) => {
    setUploadingAudio(true)
    const functions = getFunctions();
    const updateSessionData = httpsCallable(functions, 'updateSessionData');
    const updateData = { additionalNotes: props.additionalNotes, templateId: props.selectedTemplate?.id, patientQuestionnaireId: props.selectedQuestionnaire?.id, followupUsed: props.followup };
    await updateSessionData({ userId: user.value.uid, sessionId: sessionId.value, updateData });
    trackEvent(RECORDER_UPDATE_SESSION_DATA, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, updateData })
    try {
        await uploadAudio(patientId.value, sessionId.value, blob, user.value.uid, user.value.email);
        trackEvent(AUDIO_UPLOADED, { newRecorder: true, userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, blob })
        toggleDrawer()
        router.push({ name: "SessionDetails", params: { patientId: patientId.value, id: sessionId.value } });
        setOverlay(false)
        await clear()

    } catch (err) {
        setOverlay(false)
        setDownloadAudioDialog(true)
        trackEvent(RECORDER_SUBMIT_AUDIO_ERROR, { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId.value, patientId: patientId.value, blob, error: { errorName: err.name, errorMessage: err.message, errorStack: err.stack } })
    } finally {
        setUploadingAudio(false)
    }
}

watch(countDownFinished, (val) => {
    if (val) finishRecording()
})

</script>

<style scoped>
.action-button {
    text-transform: none;
}

.bittersweet-button {
    color: var(--bittersweet) !important;
}

.white--button {
    color: white !important;
}
</style>