//This file contains functions to handle event tracking for different services like GTM and PostHog.
// analyticsService.js
import posthog from "posthog-js"; // assuming you've installed posthog-js

//Event names

// sessionId
export const NEW_SESSION_CREATED = "New session created";
//NEW_PATIENT_CREATED gets userId: user.value.uid, userEmail
export const NEW_PATIENT_CREATED = "New patient created";
export const NEW_PATIENT_CREATED_ERROR = "New patient created error";
//NEW_USER_SIGNUP gets { userId: res.user.uid, userEmail: email.value, method: "Email/Pass" }
export const NEW_USER_SIGNUP = "New user signup";
//DELETE_AUDIO gets { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
export const DELETE_AUDIO = "Delete audio";
export const DELETE_PATIENT = "Delete patient";
//DELETE_SESSION gets { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
export const DELETE_SESSION = "Delete session";
//DELETE_SESSION_EVADED gets { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
export const DELETE_SESSION_EVADED = "Delete session evaded";
//AUDIO_UPLOADED gets { userId: user.value.uid, userEmail: user.value.email, source: "Web" })
export const AUDIO_UPLOADED = "Audio uploaded";
//NEW_FEEDBACK_SUBMITTED gets { userId: user.value.uid, userEmail: user.value.email, feedback: feedback.value })
export const NEW_FEEDBACK_SUBMITTED = "New feedback submitted";
//CREATE_PATIENT_INSTRUCTIONS gets { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
export const CREATE_PATIENT_INSTRUCTIONS = "Create patient instructions";
//TEMPLATE_SHARED gets { userId: user.value.uid, userEmail: user.value.email, emailToShare: emailToShare.value })
export const TEMPLATE_SHARED = "Template shared";
//TEMPLATE_SUPPORT 
export const TEMPLATE_SUPPORT = "Template support";
//FORGOT_PASSWORD gets { userEmail: this.email })
export const FORGOT_PASSWORD = "Forgot password";
//ERROR_UPLOAD_AUDIO gets { userId: user.value.uid, patientId: patientId, sessionId: sessionId, message: err.message })
export const ERROR_UPLOAD_AUDIO = "Error uploading audio";
//UPGRADE_PRO gets { userId: user.value.uid, source: details/banner })
export const UPGRADE_PRO = "Upgrade to pro";
//DISPLAY_FORCE_BANNER gets { userId: user.value.uid, sessions: numberOfSessions })
export const DISPLAY_FORCE_BANNER = "Display force banner";
//COPY_REFERAL gets { userId: user.value.uid, userEmail: user.value.email, copy: code/link })
export const COPY_REFERAL = "Copy referal";
//Click on send email { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId })
export const SEND_EMAIL = "Send email";
//Upon detecting outdated web MdHub version { userId: user.value.uid })
export const OUTDATED_VERSION = "Outdated MdHub Web version";
//NOTE_STATUS_UPDATE gets { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId , prevStatus, currentStatus })
export const NOTE_STATUS_UPDATE = "Note status update";
//NOTE_SECTION_COPY
export const NOTE_SECTION_COPY = "Note section copy";
//COPY_DISABLED
export const COPY_DISABLED = "Copy disabled";
//NOTE_SECTION_REGENERATE
export const NOTE_SECTION_REGENERATE = "Note section regenerate";
//UPDATE_CLINICAL_TEMPLATE gets { userId: user.value.uid, userEmail: user.value.email, clinicalid: clinicalId.value , templateId, templateId.id })
export const UPDATE_CLINICAL_TEMPLATE = "Clinical template updated";
//CREATE_CLINICAL_TEMPLATE gets { userId: user.value.uid, userEmail: user.value.email, clinicalid: clinicalId.value , templateId, res.id })
export const CREATE_CLINICAL_TEMPLATE = "Clinical template created";
//CLINICAL_TEMPLATE_SELECTED gets { userId: user.value.uid, userEmail: user.value.email, clinicId: user.value.clinicId, templateId: doc.id, sessiondId: sessionId })
export const CLINICAL_TEMPLATE_SELECTED = "Clinical template selected";
// ZOOM_RECORDING_UPDATE_MEETING gets { userId: userId, patientId: patientId, token: token, sessionId: res.id, source: "Web" })
export const ZOOM_RECORDING_UPDATE_MEETING = "Zoom recording meeting update";
//ZOOM_RECORDING_STARTED  { userId: userId, token: token, patientId: patientId, source: "Web" }
export const ZOOM_RECORDING_STARTED = "Zoom recording started";
//ZOOM_RECORDING_ENDED { userId: this.userId, patientId: this.patientId, source: "Web" }
export const ZOOM_RECORDING_ENDED = "Zoom recording ended";
//LOGIN_FROM_ZOOM {email}
export const LOGIN_FROM_ZOOM = 'User login from zoom';
// RECORDING_CONTINUED, { userId: this.userId, patientId: this.patientId, source: "Web"}
export const RECORDING_CONTINUED = "Recording continued";
// RECORDING_PAUSED, { userId: this.userId, patientId: this.patientId, source: "Web" }
export const RECORDING_PAUSED = "Recording paused";
// RECORDING_STOPPED, { userId: this.userId, patientId: this.patientId, way: "mic/headphones", source: "Web" }
export const RECORDING_STOPPED = "Recording stopped";
// START_MIC, { userId: this.userId, patientId: this.patientId, way: "mic/headphones", source: "Web" }
export const START_MIC = "Starting microphone recording";
// STOPPING_MIC, { userId: this.userId, patientId: this.patientId }
export const STOPPING_MIC = "Stopping microphone recording";
// STOPPED_MIC, { userId: this.userId, patientId: this.patientId }
export const STOPPED_MIC = "Microphone recording stopped";
// ERROR_MIC, { userId: this.userId, patientId: this.patientId, error: error }
export const ERROR_MIC = "Error starting microphone recording";
// CAPTURING_MIC, { userId: this.userId, patientId: this.patientId }
export const CAPTURING_MIC = "Capturing microphone";
// ERROR_CAPTURING_MIC, { userId: this.userId, patientId: this.patientId, error: error }
export const ERROR_CAPTURING_MIC = "Error capturing microphone";
// RECORDING_STARTED, { userId: this.userId, patientId: this.patientId, way:"mic/headphones" source: "Web" }
export const RECORDING_STARTED = "Recording started";
// RECORDING_SESSION_CREATED, { userId: userId, userEmail: user.value.email, patientId: patientId, sessionId: result.data.id source: "Web" }
export const RECORDING_SESSION_CREATED = "Recording session created";
// AUDIO_UPLOAD_STARTED, { userId: userId, userEmail: user.value.email, patientId: patientId, source: "Web" }
export const AUDIO_UPLOAD_STARTED = "Audio upload started";
// SEND_SECURE_EMAIL_START { userId: this.patient.userId, userEmail: this.userEmail, patientId: this.patient.id, sessionId: this.sessionId, options, source: "Web" }
export const SEND_SECURE_EMAIL_START = "Start sending secure email";
// SEND_SECURE_EMAIL_SUCCESS { userId: this.patient.userId, userEmail: this.userEmail, patientId: this.patient.id, sessionId: this.sessionId, source: "Web" }
export const SEND_SECURE_EMAIL_SUCCESS = "Send secure email success";
//USER_SOURCE gets userId: user.value.uid, email, source
export const USER_SOURCE = "User source";
// INSTRUCTIONS_SEARCH_AND_REPLACE, { userId: this.user.uid, userEmail: this.user.email, search: this.searchModel, replace: this.replaceModel, sessionId: this.sessionId }
export const INSTRUCTIONS_SEARCH_AND_REPLACE =
	"User instructions search and replace";
// NOTES_SEARCH_AND_REPLACE, { userId: this.userId, userEmail: this.userEmail, search: this.searchModel, replace: this.replaceModel, sessionId: this.sessionId }
export const NOTES_SEARCH_AND_REPLACE = "User notes search and replace";
// RELOAD_ATTEMPT_WHILE_RECORDING, { userId: this.userId, userEmail: this.user.email, patientId: this.patientId, sessionId: this.sessionId, source: "Web" }
export const RELOAD_ATTEMPT_WHILE_RECORDING =
	"User tried to refresh while recording or uploading and audio";
// QUESTION_ASKED, {useriId:this.user.uid, userEmail: this.user.email, patientId: this.patientId, prompt}
export const QUESTION_ASKED = "User asked the RAG";
// QUESTION_ASKED, {useriId:this.user.uid, userEmail: this.user.email, patientId: this.patientId, answer}
export const QUESTION_ANSWER = "Answered question from RAG";
//AUDIO_SAVED_LOCALLY, { userId: user.value.id, patientId: props.patientId, sessionId: sessionId.value })
export const AUDIO_SAVED_LOCALLY = "Audio saved locally";

//DELETING_PATIENT_MEDICATION gets { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, source: "Web" }
export const DELETING_PATIENT_MEDICATION = "Patient Medication Deleted";
//UPDATING_PATIENT_MEDICATION gets { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, updatedPatientMedicaiton: this.editedItem, source: "Web" }
export const UPDATING_PATIENT_MEDICATION =
	"Patient Medication List Updated By The User";
//RETRIEVED_PATIENT_MEDICATION gets { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, source: "Web" }
export const RETRIEVED_PATIENT_MEDICATION = "Patient Medication Retrieved";
// RETRIEVED_RESPONSE_FROM_RAG gets { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: response, source: "Web" }
export const RETRIEVED_RESPONSE_FROM_RAG =
	"Patient Medication Response From RAG";
// MAPPED_RESPONSE_FROM_RAG { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: newSessionsWithMedication, source: "Web" }
export const MAPPED_RESPONSE_FROM_RAG = "Mapped Response From Rag";
// SESSIONS_WITHOUT_MEDICATION { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: sessionsWithoutMedications, source: "Web" }
export const SESSIONS_WITHOUT_MEDICATION = "Sessions Without Medication";
// PATIENT_MEDICATIONS_UPDATED { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: updateMedications, source: "Web" }
export const PATIENT_MEDICATIONS_UPDATED =
	"Patient Medications Updated From Rag";
// SHOULD_VECTORIZE_MEDICATION { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, shouldVectorize, diff, source: "Web" }
export const SHOULD_VECTORIZE_MEDICATION = "Should Vectorize Data";
// PATIENT_MEDICATIONS_UPDATE_FAILED { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, error, source: "Web" }
export const PATIENT_MEDICATIONS_UPDATE_FAILED =
	"Fail to update data in firebase";
// PATIENT_MEDICATIONS_SORTED_BY_DATE { userId: user.value.uid, userEmail: user.value.email, patientId: patientId, data: sortedData, source: "Web" }
export const PATIENT_MEDICATIONS_SORTED_BY_DATE =
	"Patient Medications Sorted By Date";
//NOTE_STATUS_UPDATE_FAILURE gets { userId: user.value.uid, userEmail: user.value.email, sessionId: sessionId , prevStatus, currentStatus, error })
export const NOTE_STATUS_UPDATE_FAILURE = "Note status update failed";
//NEW_USER_SIGNUP_FAILED gets { userId: '-', userEmail: email.value, error, method: "Email/Pass" }
export const NEW_USER_SIGNUP_FAILED = "New user signup failed";
//INDEXES_RETRIEVED_FROM_RAG gets {userId:user.value.uid, userEmail: user.value.email, patientId}
export const INDEXES_RETRIEVED_FROM_RAG = "Indexes retrieved from RAG";
//ERROR_INDEXES_RETRIEVED_FROM_RAG gets {userId:user.value.uid, userEmail: user.value.email, patientId}
export const ERROR_INDEXES_RETRIEVED_FROM_RAG = "Error retrieving indexes from RAG";
//USER_FEATURE_FEEDBACK gets { userId: this.userId, userEmail: this.userEmail, feature: this.feature,source: "Web" }
export const USER_FEATURE_FEEDBACK = "User feature feedback";
// ADDING_PATIENT_MEDICATION, { userId: this.user.uid, userEmail: this.user.email, patientId: this.patientId, data: this.editedItem, source: "Web" }
export const ADDING_PATIENT_MEDICATION = "Manually adding patient medication";
// NEW_FEATURES, { userId, userEmail }
export const NEW_FEATURES = 'New features'
// AUDIO_SOURCE_CHANGED, { userId: userId, userEmail: user.value.email, patientId: patientId, sessionId: result.data.id, source: "macbook" }
export const AUDIO_SOURCE_CHANGED = "Audio source changed";
// CAPTCHA_ERROR, { userEmail: user.value.email }
export const CAPTCHA_ERROR = "Error in captcha";
// LOGIN_ERROR, { userEmail: user.value.email, error: error }
export const LOGIN_ERROR = "Error login in";
//LOGOUT {email}
export const LOGOUT = 'User logged out from mdhub';
// NOTIFICATION_PERMISSION_GRANTED, { userId: user.value.uid }
export const NOTIFICATION_PERMISSION_GRANTED = 'Notifications permission granted';
// NOTIFICATION_PERMISSION_NOT_GRANTED, { userId: user.value.uid }
export const NOTIFICATION_PERMISSION_NOT_GRANTED = 'Notifications permission not granted';
// NOTIFICATION_SHOWN, { userId: user.value.uid }
export const NOTIFICATION_SHOWN = 'Notification shown';
// TEMPLATE_CHANGED
export const DEFAULT_TEMPLATE_CHANGED = "Default template Changed";

//Questionnaires 
//QUESTIONNAIRE_SAVED
export const QUESTIONNAIRE_SAVED = 'Questionnaire saved';
//QUESTIONNAIRE_ITEM_DUPLICATED
export const QUESTIONNAIRE_ITEM_DUPLICATED = 'Questionnaire item duplicated';
//QUESTIONNAIRE_DETAILS_SAVED
export const QUESTIONNAIRE_DETAILS_SAVED = 'Questionnaire details saved';
//QUESTIONNAIRE_SUBMITTED
export const QUESTIONNAIRE_SUBMITTED_BY_PATIENT = 'Questionnaire submitted by patient'
//QUESTIONNAIRE_SUBMITTED_BY_PATIENT_FAILED
export const QUESTIONNAIRE_SUBMITTED_BY_PATIENT_FAILED = 'Failed to submit questionnaire by patient'
//QUESTIONNAIRE_SENT
export const QUESTIONNAIRE_SENT = 'Questionnaire sent to patient'
//QUESTIONNAIRE_CREATED
export const QUESTIONNAIRE_CREATED = 'Questionnaire created'
//QUESTIONNAIRE_CREATED_FAILED
export const QUESTIONNAIRE_CREATED_FAILED = 'Failed to create questionnaire'
//QUESTIONNAIRE_MOVE_ITEM_UP
export const QUESTIONNAIRE_MOVE_ITEM_UP = 'Questionnaire move item up'
//QUESTIONNAIRE_MOVE_ITEM_DOWN
export const QUESTIONNAIRE_MOVE_ITEM_DOWN = 'Questionnaire move item down'
//QUESTIONNAIRE_SUMMARY_EDITED
export const QUESTIONNAIRE_SUMMARY_EDITED = 'Questionnaire summary edited'
//QUESTIONNAIRE_SUMMARY_EDITED_FAILED
export const QUESTIONNAIRE_SUMMARY_EDITED_FAILED = 'Failed to edit questionnaire summary'
//QUESTIONNAIRE_IMPORTED
export const QUESTIONNAIRE_IMPORTED = 'Questionnaire imported'
//QUESTIONNAIRE_IMPORTED_FAILED
export const QUESTIONNAIRE_IMPORTED_FAILED = 'Failed to import questionnaire'
//QUESTIONNAIRE_SUPPORT
export const QUESTIONNAIRE_SUPPORT = 'Questionnaire support'
//QUESTIONNAIRE_PAGE_VISIT
export const QUESTIONNAIRE_PAGE_VISIT = 'Questionnaire page visit'
//QUESTIONNAIRE_VIDEO_CLICK
export const QUESTIONNAIRE_VIDEO_CLICK = 'Questionnaire video click'
//TEMPLATE_VIDEO_CLICK
export const TEMPLATE_VIDEO_CLICK = 'Template video click'
// IMPORTED_HISTORY
export const IMPORTED_HISTORY = 'Imported history';
// IMPORTED_HISTORY_FAILED
export const IMPORTED_HISTORY_FAILED = 'Failed to import history';
// IMPORTED_HISTORY_ON_CREATE_PATIENT
export const IMPORTED_HISTORY_ON_CREATE_PATIENT = 'Imported history on create patient';
// IMPORTED_HISTORY_ON_CREATE_PATIENT_FAILED
export const IMPORTED_HISTORY_ON_CREATE_PATIENT_FAILED = 'Failed to import history on create patient';
// LIVE_TRANSCRIPT_OPENED
export const LIVE_TRANSCRIPT_OPENED = 'User opened the Live transcript';
//LIVE_TRANSCRIPT_CLOSED
export const LIVE_TRANSCRIPT_CLOSED = 'User closed the Live transcript';
//ZERO_BLOB_AUDIO_RECEIVED_MIC 
export const ZERO_BLOB_AUDIO_RECEIVED_MIC = 'Zero bytes audio blob received from mic'

//NEW RECORDER EVENTS
//RECORDER_CREATE_SESSION
export const RECORDER_CREATE_SESSION = 'Recorder create session';
//RECORDER_START_WITHOUT_HEADPHONES
export const RECORDER_START_WITHOUT_HEADPHONES = 'Recorder start without headphones'
//RECORDER_START_WITHOUT_HEADPHONES_ERROR
export const RECORDER_START_WITHOUT_HEADPHONES_ERROR = 'Recorder start without headphones error'
//RECORDER_START_WITH_HEADPHONES
export const RECORDER_START_WITH_HEADPHONES = 'Recorder start with headphones'
//RECORDER_START_WITH_HEADPHONES_ERROR
export const RECORDER_START_WITH_HEADPHONES_ERROR = 'Recorder start with headphones error'
//RECORDER_PAUSE
export const RECORDER_PAUSE = 'Recorder pause'
//RECORDER_RESUME
export const RECORDER_RESUME = 'Recorder resume'
//RECORDER_RESUME_ERROR
export const RECORDER_RESUME_ERROR = 'Recorder resume error'
//RECORDER_TEMPLATE_SELECTED
export const RECORDER_TEMPLATE_SELECTED = 'Recorder template selected'
//RECORDER_QUESTIONNAIRE_SELECTED
export const RECORDER_QUESTIONNAIRE_SELECTED = 'Recorder questionnaire selected'
//RECORDER_STOP_RECORDING
export const RECORDER_STOP_RECORDING = 'Recorder stop recording'
//RECORDER_UPDATE_SESSION_DATA
export const RECORDER_UPDATE_SESSION_DATA = 'Recorder update session data'
//RECORDER_SUBMIT_AUDIO
export const RECORDER_SUBMIT_AUDIO = 'Recorder submit audio'
//RECORDER_SUBMIT_AUDIO_ERROR
export const RECORDER_SUBMIT_AUDIO_ERROR = 'Recorder submit audio error'
//RECORDER_DOWNLOADED_AUDIO
export const RECORDER_DOWNLOADED_AUDIO = 'Recorder downloaded audio'
//RECORDER_UPLOAD_FILE
export const RECORDER_UPLOAD_FILE = 'Recorder upload file'
//RECORDER_UPLOAD_FILE_ERROR
export const RECORDER_UPLOAD_FILE_ERROR = 'Recorder upload file error'
//RECORDER_LIVE_TRANSCRIPT_ZERO_BLOB
export const RECORDER_LIVE_TRANSCRIPT_ZERO_BLOB = 'Recorder live transcript zero blob'
//RECORDER_TRIED_REFRESH
export const RECORDER_TRIED_REFRESH = 'Recorder tried to refresh'
//RECORDER_GET_DISPLAY_MEDIA
export const RECORDER_GET_DISPLAY_MEDIA = 'Recorder get display media'
//RECORDER_DATA_AVAILABLE
export const RECORDER_DATA_AVAILABLE = 'Recorder data available'
//RECORDER_NO_DATA_AVAILABLE
export const RECORDER_NO_DATA_AVAILABLE = 'Recorder no data available'
//RECORDER_DEVICE_CHANGE
export const RECORDER_DEVICE_CHANGE = 'Recorder device change'
// RECORDER_DEVICE_CHANGE_ERROR
export const RECORDER_DEVICE_CHANGE_ERROR = 'Recorder device change error'
//RECORDER_CONNECT_MICROPHONE_AUDIO_DEVICES
export const RECORDER_CONNECT_MICROPHONE_AUDIO_DEVICES = 'Recorder connect microphone audio devices'
//RECORDER_CONNECT_MICROPHONE_DEFAULT_AUDIO_DEVICE
export const RECORDER_CONNECT_MICROPHONE_DEFAULT_AUDIO_DEVICE = 'Recorder connect microphone default audio device'
//RECORDER_CONNECT_MICROPHONE_AUDIO_STREAM
export const RECORDER_CONNECT_MICROPHONE_AUDIO_STREAM = 'Recorder connect microphone audio stream'
//RECORDER_DISCARD_SESSION
export const RECORDER_DISCARD_SESSION = 'Recorder discard session'
//RECORDER_DISCARD_SESSION_ERROR
export const RECORDER_DISCARD_SESSION_ERROR = 'Recorder discard session error'
//RECORDER_TOGGLE_DRAWER
export const RECORDER_TOGGLE_DRAWER = 'Recorder toggle drawer'
//RECORDER_STOP_TIMER_ERROR
export const RECORDER_STOP_TIMER_ERROR = 'Recorder stop timer error'
//RECORDER_STOP_RECORDING_ERROR
export const RECORDER_STOP_RECORDING_ERROR = 'Recorder stop recording error'
//RECORDER_STOP_RECORDING_ERROR_IN_COMPONENT
export const RECORDER_STOP_RECORDING_ERROR_IN_COMPONENT = 'Recorder stop recording error in component'
//RECORDER_CREATE_BLOB_ERROR
export const RECORDER_CREATE_BLOB_ERROR = 'Recorder create blob error'
//RECORDER_FINISH_LIVESCRIPT_ERROR
export const RECORDER_FINISH_LIVESCRIPT_ERROR = 'Recorder finish live script error'
//RECORDER_AUDIO_PROGRESS
export const RECORDER_AUDIO_PROGRESS = 'Recorder audio progress'
//RECORDER_MUTE_LISTENER
export const RECORDER_MUTE_LISTENER = 'Recorder mute listener'
//RECORDER_MUTE_LISTENER_PAUSED_AUTOOMATICALLY
export const RECORDER_MUTE_LISTENER_PAUSED_AUTOOMATICALLY = 'Recorder mute listener paused automatically'
//RECORDER_DOWNLOAD_NO_AUDIO_BLOB
export const RECORDER_DOWNLOAD_NO_AUDIO_BLOB = 'Recorder download no audio blob'
//RECORDER_REACHED_TWO_HOURS
export const RECORDER_REACHED_TWO_HOURS = 'Recorder reached two hours'
//RECORDER_CONTINUED_AFTER_TWO_HOURS
export const RECORDER_CONTINUED_AFTER_TWO_HOURS = 'Recorder continued after two hours'
//RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_TIMEOUT
export const RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_TIMEOUT = 'Recorder finished after two hours with timeout'
//RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_USER_ACTION
export const RECORDER_FINISHED_AFTER_TWO_HOURS_WITH_USER_ACTION = 'Recorder finished after two hours with user action'
//RECORDER_MEDIA_RECORDER_OBJECT
export const RECORDER_MEDIA_RORDER_OBJECT = 'Recorder media recorder object'
//RECORDER_TRACK_OBJECT
export const RECORDER_TRACK_OBJECT = 'Recorder track object'
//RECORDER_NO_STREAM_LISTENER_PAUSED
export const RECORDER_NO_STREAM_LISTENER_PAUSED = 'Recorder no stream listener paused'
//RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR
export const RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR = 'Recorder deleted local blob on clear'
//RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR_ERROR
export const RECORDER_DELETED_LOCAL_BLOB_ON_CLEAR_ERROR = 'Recorder deleted local blob on clear error'

//AUDIO_LOCAL_GET
export const AUDIO_LOCAL_GET = 'Audio local get'
//AUDIO_LOCAL_SYNC
export const AUDIO_LOCAL_SYNC = 'Audio local sync'
//AUDIO_LOCAL_SYNC_ERROR
export const AUDIO_LOCAL_SYNC_ERROR = 'Audio local sync error'
//AUTO_DELETE_SESSION_FROM_INDEXED_DB
export const AUTO_DELETE_SESSION_FROM_INDEXED_DB = 'Automatically elete session from indexedDB'

//TREATMENT_PLAN_CREATE
export const TREATMENT_PLAN_CREATE = 'Treatment plan created'
//TREATMENT_PLAN_UPDATE
export const TREATMENT_PLAN_UPDATE = 'Treatment plan updated'
//TREATMENT_PLAN_CREATE_ERROR
export const TREATMENT_PLAN_CREATE_ERROR = 'Treatment plan create error'
//TREATMENT_PLAN_UPDATE_ERROR
export const TREATMENT_PLAN_UPDATE_ERROR = 'Treatment plan update error'

//CLICKED_SESSION_USED_FOLLOWUP
export const CLICKED_SESSION_USED_FOLLOWUP = 'Clicked session used followup'
const gtmServerUrl = "https://server-side-tagging-hus5tgchaq-uc.a.run.app";

function getGTMInstance() {
	return window.$gtm;
}

export async function trackEvent(eventName, eventData) {
	// For PostHog
	posthog.capture(eventName, eventData);

	// For Google Tag Manager
	const gtm = getGTMInstance();

	if (gtm) {
		gtm.push({
			event: eventName,
			...eventData,
		});
	}
}
