import { BaseAudioRecorder } from './recorder.base.js';
import { trackEvent, RECORDER_GET_DISPLAY_MEDIA } from '@/utilities/analyticsService';

export class TabAudioRecorder extends BaseAudioRecorder {
    constructor(userId, patientId, sessionId) {
        super(userId, patientId, sessionId);
        this.displayStream = null;
    }

    async getMediaStream() {
        try {
            this.displayStream = await navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: true,
            });

            trackEvent(RECORDER_GET_DISPLAY_MEDIA, { userId: this.userId, userEmail: this.userEmail, source: "Web", patientId: this.patientId, sessionId: this.sessionId, displayStream: JSON.stringify(this.displayStream) });
            console.log('displayStream', this.displayStream);

            let audioTracks = this.displayStream.getAudioTracks();

            // For Safari & Firefox
            if (audioTracks.length === 0) {
                throw new Error('NoAudioTrackError')
            }

            this.audioContext = new AudioContext();
            this.destination = this.audioContext.createMediaStreamDestination();

            const displayAudioSource = this.audioContext.createMediaStreamSource(new MediaStream(audioTracks));
            displayAudioSource.connect(this.destination);



            await this.connectMicrophone();

            navigator.mediaDevices.addEventListener('devicechange', this.deviceChangeHandler);

            return this.destination.stream;
        } catch (error) {
            console.error('Error getting media streams:', error);
            throw error;
        }
    }

    stop() {
        super.stop();

        if (this.displayStream) {
            this.displayStream.getTracks().forEach(track => track.stop());
            this.displayStream = null;
        }
    }

    clear() {
        super.clear();

        if (this.displayStream) {
            this.displayStream.getTracks().forEach(track => track.stop());
            this.displayStream = null;
        }
    }
}
