<template>
    <div>
        <TemplateWizzard />
        <div v-if="!fetchingTemplate">


            <v-card class="mx-auto pb-4 px-4 text-left rounded-card" width="700" elevation="0">
                <v-progress-linear v-if="isPending" color="var(--melon)" indeterminate
                    style="width:75vw;"></v-progress-linear>
                <span class="d-flex align-center justify-space-between header-section pt-3">
                    <h3>Template Details</h3>
                    <div>
                        <v-btn class=" mr-2" variant="text" @click="toggleShareDialog"
                            :disabled="!templateHeader || (!isUserAdmin && shareClinic)">
                            <span style="text-transform: none;">Share</span>
                        </v-btn>

                        <v-btn class="save-all-btn" @click="handleSave" elevation="0" :disabled="!templateHeader"
                            color="var(--bittersweet)">
                            <v-icon color="white" class="mr-2 save-all-icon">mdi-content-save-all-outline</v-icon>
                            <span class="save-all-text" style="color:white; text-transform: none;">
                                Save
                            </span>
                        </v-btn>
                    </div>
                </span>
                <p>Use a descriptive name to remember your template</p>

                <v-text-field class="mt-4" v-model="templateHeader" label="Name *" :rules="[required]" required
                    placeholder="e.g. SOAP" />

                <v-select label="Note language" v-model="selectedLanguage"
                    :items="['English', 'Spanish', 'French', 'German', 'Arabic', 'Chinese', 'Punjabi', 'Tagalog', 'Russian']"></v-select>

                <v-select label="Specialty" v-model="selectedSpeciality"
                    :items="['Psychiatry', 'Psychology', 'Child and Adolescent Psychiatry',
                        'Geriatric Psychiatry', 'Forensic Psychiatry', 'Addiction Psychiatry', 'Consultation-Liaison Psychiatry', 'Neuropsychiatry', 'Psychosomatic Medicine', 'Emergency Psychiatry', 'Sleep Medicine', 'Clinical Psychology', 'Counseling Psychology', 'School Psychology', 'Industrial-Organizational Psychology', 'Health Psychology', 'Neuropsychology', 'Forensic Psychology', 'Sports Psychology', 'Developmental Psychology', 'Social Psychology', 'Experimental Psychology',
                        'Community Psychology', 'Anesthesiology', 'Cardiology', 'Dermatology', 'Emergency Medicine', 'Endocrinology', 'Family Medicine', 'Gastroenterology', 'General Surgery', 'Geriatrics', 'Hematology', 'Infectious Disease', 'Internal Medicine', 'Nephrology', 'Neurology', 'Nutritionist', 'Obstetrics and Gynecology', 'Oncology', 'Ophthalmology', 'Orthopedics', 'Otolaryngology', 'Pediatrics', 'Physical Medicine and Rehabilitation', 'Plastic Surgery', 'Pulmonology', 'Radiology', 'Rheumatology', 'Urology', 'Other',]"></v-select>

                <v-checkbox label="Share with Community" hide-details v-model="shareCommunity"></v-checkbox>
                <v-checkbox label="Share with Clinic" hide-details v-model="shareClinic"></v-checkbox>
            </v-card>

            <v-container group="sections">
                <draggable v-model="inputValues" filter=".exclude-this-item" preventOnFilter group="sections"
                    @start="drag = true" @end="drag = false">
                    <template #item="{ element, index }">
                        <v-card class="mx-auto mb-3 rounded-card " elevation="0" width="700"
                            :disabled='!isUserAdmin && shareClinic'>
                            <v-card-text>
                                <TemplateSection :element="element" :index="index" />
                            </v-card-text>
                            <v-card-actions>
                                <TemplateSectionActions @save="handleSave" @remove="remove(index)"
                                    @moveUp="moveUp(index)" @moveDown="moveDown(index)" :isPendingSave="isPending"
                                    :templateHeader="templateHeader" />
                            </v-card-actions>
                        </v-card>
                    </template>
                </draggable>
            </v-container>

            <v-card class=" mx-auto pa-4" width="700" elevation="0">
                <v-btn class="ml-16" variant="plain" @click="addInputField" :disabled='!isUserAdmin && shareClinic'> Add
                    new
                    section</v-btn>
            </v-card>
        </div>

        <div class="d-flex flex-column align-center" v-else>
            <v-progress-circular class="mt-12" color="primary" indeterminate></v-progress-circular>
        </div>


        <v-dialog v-model="showShareDialog" persistent max-width="600px">
            <v-card class="pa-4" :disabled='!isUserAdmin && shareClinic'>
                <v-card-title class="headline">Share Template</v-card-title>
                <v-card-subtitle>Enter the email of the person you want to share this template with:</v-card-subtitle>
                <v-card-text>
                    <v-text-field label="Email" type="email" v-model="emailToShare" :rules="[emailRules]" required
                        variant="outlined"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="shareTemplate">Share</v-btn>
                    <v-btn color="primary" text @click="showShareDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="2000" color="gray">
            <span class="d-flex justify-center"> Template saved successfully</span>
        </v-snackbar>
        <v-snackbar v-model="errorSnackbar" :timeout="2000" color="bittersweet">
            <span style="color:white;">You must include at least one section in your template.</span>
        </v-snackbar>
        <Feedback :show-on-left="true" />
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import draggable from 'vuedraggable'
import TemplateSection from '../components/TemplateSection.vue';
import TemplateSectionActions from '../components/TemplateSectionActions.vue'
import TemplateWizzard from '../components/TemplateWizzard.vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRoute, useRouter } from 'vue-router';
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { trackEvent, TEMPLATE_SHARED, CREATE_CLINICAL_TEMPLATE, UPDATE_CLINICAL_TEMPLATE } from '@/utilities/analyticsService';
import getDocument from '@/composables/getDocument'
import Feedback from '@/components/Feedback.vue';

const route = useRoute();
const { err, addDoc } = useCollection('summary-templates');
const { user } = getUser();
const router = useRouter()

const snackbar = ref(false)


const templateHeader = ref('')
const selectedLanguage = ref('')
const selectedSpeciality = ref('');
const shareCommunity = ref(false);
const isUserAdmin = ref(false);
const shareClinic = ref(false);
const inputValues = ref([])
const drag = ref(false)
const isPending = ref(false)

const fetchingTemplate = ref(false)

onMounted(async () => {
    fetchingTemplate.value = true
    const functions = getFunctions();
    const getTemplateById = httpsCallable(functions, 'getTemplateById');
    const res = await getTemplateById({ templateId: route.params.id });

    console.log(res.data.template)
    templateHeader.value = res.data.template.templateHeader;
    selectedLanguage.value = res.data.template.language;
    selectedSpeciality.value = res.data.template.selectedSpeciality;
    shareCommunity.value = res.data.template.shareCommunity;
    shareClinic.value = res.data.template.shareClinic;
    // inputValues.value = res.data.template.sections;
    const transformedArray = res.data.template.template.map(item => {
        const [title, dlevel, style] = item.split('/');
        return {
            item: title,
            dlevel: dlevel,
            style: style,
        };
    })
    inputValues.value = transformedArray;
    fetchingTemplate.value = false
})



const required = (v) => !!v || 'Field is required';

const clinicId = ref(null);
const { documents: userObject } = getCollection('user');

watch(userObject, () => {
    clinicId.value = userObject.value[0]?.clinicId || null
    isUserAdmin.value = userObject.value[0]?.isAdmin || false;
})
const addInputField = () => {
    inputValues.value.push({ item: '', dlevel: '', style: 'Bullet points' });
}
const errorSnackbar = ref(false);

const handleSave = async () => {
    isPending.value = true
    const { error, document: clinic } = clinicId.value ? getDocument('clinics', clinicId.value) : {}

    let customTemplate = inputValues.value
        .filter(value => value.item !== '')
        .map(value => {

            var sectionTitle = value.item
            var sectionStyle = value.style
            var sectionOwnWords = value.dlevel.replaceAll('/', ',')

            return sectionTitle + "/" + sectionOwnWords + "/" + sectionStyle;
        });

    if (customTemplate.length === 0) {
        isPending.value = false;
        errorSnackbar.value = true;
        return;
    }
    try {

        const { updateDocument } = await useDocument('summary-templates', route.params.id);

        const res = await updateDocument({
            templateHeader: templateHeader.value,
            updatedAt: timestamp(),
            template: customTemplate,
            language: selectedLanguage.value,
            selectedSpeciality: selectedSpeciality.value,
            shareCommunity: shareCommunity.value,
            shareClinic: shareClinic.value,
            clinicId: shareClinic.value ? clinicId.value : null
        });

        if (isUserAdmin.value && shareClinic.value && clinicId.value) {

            const restOfTemplates = clinic?.value.templates.filter(template => template.id !== route.params.id) || []

            const { updateDocument: updateClinic } = await useDocument('clinics', clinicId.value);

            const updatedTemplate = shareClinic.value ? {
                id: route.params.id,
                name: templateHeader.value
            } : {};

            const concatTemplates = [...restOfTemplates, updatedTemplate];
            await updateClinic({
                templates: concatTemplates
            });

            trackEvent(UPDATE_CLINICAL_TEMPLATE, { userId: user.value.uid, email: user.value.email, clinicId: clinicId.value, templateId: route.params.id });

        }

        snackbar.value = true
        isPending.value = false
        // router.push({ name: 'Templates' })
    }
    catch (error) {
        console.log('Something went wrong while trying to save the template:', error);
    }

}


const moveUp = (index) => {
    if (index > 0) {
        const item = inputValues.value.splice(index, 1)[0];
        inputValues.value.splice(index - 1, 0, item);
    }
}
const moveDown = (index) => {
    if (index < inputValues.value.length - 1) {
        const item = inputValues.value.splice(index, 1)[0];
        inputValues.value.splice(index + 1, 0, item);
    }
}

const remove = (index) => {
    inputValues.value.splice(index, 1);
}

const showShareDialog = ref(false);
const emailToShare = ref('');

const toggleShareDialog = () => {
    showShareDialog.value = !showShareDialog.value;
};

const shareTemplate = () => {
    trackEvent(TEMPLATE_SHARED, { userId: user.value.uid, userEmail: user.value.email, emailToShare: emailToShare.value })
    showShareDialog.value = false;
};
</script>

<style scoped>
.rounded-card {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}
</style>