import { trackEvent, AUTO_DELETE_SESSION_FROM_INDEXED_DB } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'

let { user } = getUser()

const DB = "mdhub_db"
const AUDIOS = "audios"
const READWRITE = "readwrite"
const READONLY = "readonly"

let db
function openDB() {
    if (db) return Promise.resolve(db)

    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB, 1)

        request.onerror = (event) => {
            console.error(`Database error: ${event.target.error?.message}`)
            reject(event)
        }

        request.onsuccess = (event) => {
            //console.log('onsuccess', event)
            db = event.target.result
            resolve(db)

            db.onerror = (event) => {
                console.error(`Databse error: ${event.target.error?.message}`)
            }
        }

        request.onupgradeneeded = (event) => {
            console.log('onupgradeneeded', event)
            db = event.target.result
            db.createObjectStore(AUDIOS, { keyPath: "sessionId" })
        }
    })
}

/**
 * @param {string} sessionId - The id of the session
 * @param {Blob} audio - The audio object to add
 */
function add(sessionId, audio) {
    return new Promise(async (resolve, reject) => {
        if (!db) {
            await openDB()
        }
        const request = db
            .transaction([AUDIOS], READWRITE)
            .objectStore(AUDIOS)
            .add({
                sessionId,
                updatedAt: Date.now(),
                audio
            })

        request.onsuccess = (event) => {
            resolve(event)
        }

        request.onerror = (event) => {
            reject(event)
        }
    })
}

/**
 * @param {string} sessionId - The id of the session
 */
function get(sessionId) {
    return new Promise(async (resolve, reject) => {
        if (!db) {
            await openDB()
        }

        const request = db
            .transaction([AUDIOS], READONLY)
            .objectStore(AUDIOS)
            .get(sessionId)

        request.onsuccess = (event) => {
            resolve(event.target.result)
        }

        request.onerror = (event) => {
            reject(event)
        }
    })
}

/**
 * @param {string} sessionId - The id of the session
 * @param {Blob} audio - The audio object to update
 */
function update(sessionId, audio) {
    return new Promise(async (resolve, reject) => {
        if (!db) {
            await openDB()
        }
        const request = db
            .transaction([AUDIOS], READWRITE)
            .objectStore(AUDIOS)
            .put({
                sessionId,
                updatedAt: Date.now(),
                audio
            })

        request.onsuccess = (event) => {
            console.info(`Udpated audio for session ${sessionId} with ${audio.size} bytes`)
            resolve(event)
        }

        request.onerror = (event) => {
            reject(event)
        }
    })
}

/**
 * @param {string} sessionId - The id of the session
 */
function deleteById(sessionId) {
    return new Promise(async (resolve, reject) => {
        if (!db) {
            await openDB()
        }

        const request = db
            .transaction([AUDIOS], READWRITE)
            .objectStore(AUDIOS)
            .delete(sessionId)

        request.onsuccess = (event) => {
            resolve(event)
        }

        request.onerror = (event) => {
            reject(event)
        }
    })
}

function deleteAllExpired() {
    return new Promise(async (resolve, reject) => {
        if (!db) {
            await openDB()
        }

        const request = db
            .transaction([AUDIOS], READWRITE)
            .objectStore(AUDIOS)

        const openCursor = request.openCursor()

        const oneWeekAgo = new Date()
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

        openCursor.onsuccess = (event) => {
            const cursor = event.target.result
            if (cursor) {
                if (new Date(cursor.value.updatedAt) < oneWeekAgo) {
                    console.log('Deleting from indexedDB', {
                        sessionId: cursor.value.sessionId,
                        updatedAt: cursor.value.updatedAt
                    })
                    trackEvent(AUTO_DELETE_SESSION_FROM_INDEXED_DB, { userId: user.value.id, userEmail: user.value.email, sessionId: cursor.value.sessionId, updatedAt: cursor.value.updatedAt })
                    cursor.delete()
                }
                cursor.continue()
            }
            else {
                console.log("Deleting from indexedDB: no more entries")
                resolve()
            }
        }

        openCursor.onerror = (event) => {
            console.error("Deleting from indexedDB: error", event)
            reject(event)
        }

    })
}

export { add, get, update, deleteById, deleteAllExpired }