import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import getUser from './getUser';

export const Feature = {
    Sessions: "feature_sessions",
    Questionnaires: "feature_questionnaires"
}

const { user } = getUser();

let listener = null;

export async function startAvailableFeaturesListener() {
    const userId = user.value.uid;
    console.log('Starting listening for the snapshot in collection "customer" for user ID', userId);

    const db = getFirestore();
    const customerRef = collection(db, 'customers', userId, 'subscriptions');

    listener = onSnapshot(customerRef, async (snapshot) => {
        //console.log('Received snapshot update for the user ID', { userId, data: snapshot.value });
        await getAvailableFeatures();
    }, error => {
        console.log('Received error while listening for the snapshot from "customer" collection for user ID', { userId, error });
    });
}

async function getAvailableFeatures() {
    const userId = user.value.uid;
    //console.log('Fetching features available for a user ID', userId);

    const functions = getFunctions();
    const getAvailableFeaturesForUser = httpsCallable(functions, 'getAvailableFeatures')

    try {
        const featuresRaw = (await getAvailableFeaturesForUser({ userId }))?.data;
        //console.log('Received features for user ID', { userId, featuresRaw });
        const features = mapFeatures(featuresRaw);
        //console.log('Mapped features', { features });

        localStorage.setItem('availableFeatures', JSON.stringify(features));
    } catch (error) {
        console.error('Failed to fetch feature for user ID', { userId, error });
    }
}

function mapFeatures(featuresRaw) {
    if (!featuresRaw || !Array.isArray(featuresRaw)) {
        return [];
    }

    return featuresRaw.filter(feature => Object.values(Feature).includes(feature));
}

export function resetAvailableFeatures() {
    localStorage.removeItem('availableFeatures');

    if (listener) {
        console.log('Cleaning up listener of the "customer" collection updates');
        listener();
    }
}