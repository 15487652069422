<template>
    <v-tooltip text="Copy plan to clipboard" location="bottom">
        <template v-slot:activator="{ props }">
            <v-btn class="ml-auto mr-6" v-bind="props" elevation="0" variant="text" @click="copy">
                <v-icon color="grey">mdi-content-copy</v-icon>
                <span class="ml-1">Copy Plan</span>
            </v-btn>
        </template>
    </v-tooltip>
    <v-snackbar v-model="snackbar" timeout="2000" color="gray">
        {{ snackbarText }}
    </v-snackbar>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
    treatmentPlan: Object
})

const snackbar = ref(false);
const snackbarText = ref('');

function formatTreatmentPlan(treatmentPlan, name) {
    let formattedText = 'Treatment plan for ' + name + '\n';

    treatmentPlan.goals.forEach((goal, goalIndex) => {
        formattedText += ` - Goal ${goalIndex + 1}:\n`;
        formattedText += ` - Description: ${goal.description}\n`;
        formattedText += ` - Objectives:\n`;

        goal.objectives.forEach((objective, objectiveIndex) => {
            formattedText += `       - Objective ${objectiveIndex + 1}:\n`;
            formattedText += `         - Specific: ${objective.specific}\n`;
            formattedText += `         - Metrics:\n`;

            objective.metrics.forEach((metric, metricIndex) => {
                formattedText += `           - Assessment tools ${metricIndex + 1}: ${metric}\n`;
            });

            formattedText += `         - Attainability: ${objective.attainability}\n`;
            formattedText += `         - Relevance: ${objective.relevance}\n`;
            formattedText += `         - Timeframe: ${objective.timeframe}\n`;

            formattedText += `         - Interventions:\n`;
            objective.interventions.forEach((intervention, interventionIndex) => {
                formattedText += `           - Intervention ${interventionIndex + 1}: ${intervention}\n`;
            });

            formattedText += `         - Homework:\n`;
            objective.homework.forEach((hw, hwIndex) => {
                formattedText += `           - Homework ${hwIndex + 1}: ${hw}\n`;
            });

            formattedText += '\n';
        });

        formattedText += '\n';
    });

    return formattedText;
}

function copy() {
    const formattedText = formatTreatmentPlan(props.treatmentPlan.plan.plan, props.treatmentPlan.patientName);

    navigator.clipboard.writeText(formattedText).then(() => {
        console.log('Treatment plan copied to clipboard');
        snackbarText.value = 'Treatment plan copied to clipboard';
        snackbar.value = true;
    }).catch(err => {
        console.error('Failed to copy: ', err);
        snackbarText.value = 'Failed to copy treatment plan';
        snackbar.value = true;
    });
}
</script>