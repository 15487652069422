<template>
  <v-dialog persistent width="500" v-model="notInZoomMeeting">
    <v-card title="Information">
      <v-card-text>
        Please start a meeting in order to use MdHub for Zoom
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-data-table class="rounded-table" :headers="headers" :items="filteredPatients" :loading="gettingPatients"
    :sort-by="[{ key: 'lastSessionDate', order: 'desc' }]" :hover="true">
    <template v-slot:top>
      <v-toolbar flat color="#FFF" class="rounded-toolbar">
        <v-text-field class="ml-6 custom-bg" v-model="search" prepend-icon="mdi-magnify" label="Search patients"
          base-color="#fff" single-line hide-details variant="solo-filled" clearable flat></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn class="white--text mb-2 text--lighten-1 mr-4" @click="addingHistory = true; createDialog = true"
          variant="text">
          <v-icon dense class="add-btn">mdi-plus</v-icon>
          <span class="white--text add-btn"> Import Patient </span>
        </v-btn>
        <v-btn @click="createDialog = true" color="#FF7260" class="mdhub-btn mb-2" v-bind="props">
          <v-icon class="mr-3" color="white">mdi-account-plus-outline</v-icon>
          Add Patient
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item }">
      <tr @click="onRowClick(item)" class="hand-cursor">
        <td v-if="!isZoomApp">
          <div @click.stop>
            <AudioRecorderV2 v-if="true" :patientId="item.id" :patient="item" type="icon"></AudioRecorderV2>
            <!-- <AudioRecorder v-else :patientId="item.id" :style="'icon'" /> -->
          </div>
        </td>
        <td class="py-2">
          <div class="d-flex align-center">
            <v-avatar size="50" class="mr-4" color="#e2e8f0">
              <v-overlay>
                <template v-slot:activator>
                  <span class="initials">{{ getInitial(item.patientName) }}</span>
                </template>
              </v-overlay>
            </v-avatar>
            <span class="text-left">{{ item.patientName }}</span>
          </div>
        </td>
        <td v-html="getPronouns(item.gender, item.pronoun)"></td>
        <td>{{ calculateAge(item?.dob) || '-' }}</td>
        <td>{{ item.age != null ? '-' : formattedDOB(item.dob) }}</td>
        <td>{{ item.diagnosis }}</td>
        <td>{{ formattedLastSession(item.lastSessionDate) }}</td>
        <td>
          <v-icon class="me-2 hover-icon" @click.stop="selectPatient(item)">
            mdi-square-edit-outline
          </v-icon>
          <v-icon class="hover-icon" size="small" @click.stop="deleteItem(item)">
            mdi-trash-can-outline
          </v-icon>
        </td>
      </tr>
    </template>
    <template v-slot:loading>
      <div class="d-flex flex-column align-center py-8">
        <SavingSpinner :label="'Fetching clients'" style="position:relative" />
      </div>
    </template>
    <template v-slot:no-data>
      <div class="d-flex flex-column align-center py-8">
         <p>No patients found</p>
      </div>
    </template>
  </v-data-table>
  <NewFeatures />

  <v-navigation-drawer persistent temporary location="right" :width="800" :scrim="false" v-model="createDialog"
    max-width="500px">
    <v-card style="height: 100%; overflow-y: auto; width: 100%;">
      <v-card-text>
        <v-form class="d-flex flex-column border-0" style="box-shadow: none; max-width: 100% !important;"
          @submit.prevent>
          <h4 class="text-start">Add Patient</h4>
          <v-btn icon class="absolute-top-right mr-4 mt-2" @click="createDialog = false" elevation="0">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-text-field class="mt-3" v-model="newPatient.patientName" color="accent" label="Name" variant="underlined"
            density="compact"
            :error-messages="v$?.newPatient?.patientName?.$errors?.map(e => e.$message)"></v-text-field>
          <div class="d-flex align-center justify-center">
            <v-select label="Gender" v-model="newPatient.gender" variant="underlined" color="accent"
              :items="['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other']" :menu-props="{ zIndex: 99999 }"
              :error-messages="v$?.newPatient?.gender?.$errors?.map(e => e.$message)"></v-select>
            <v-select class="ml-5" label="Pronouns" v-model="newPatient.pronoun" variant="underlined" color="accent"
              :items="['he/him', 'she/her', 'they/them']" :menu-props="{ zIndex: 99999 }"
              :error-messages="v$?.newPatient?.pronoun?.$errors?.map(e => e.$message)"></v-select>
          </div>
          <v-text-field v-if="!changeCreateAge" label="Date of birth" type="date" color="accent"
            v-model="newPatient.dob" variant="underlined" class="dob-input"
            :error-messages="v$?.newPatient?.dob?.$errors?.map(e => e.$message)"></v-text-field>
          <v-select label="Race/Ethnicity" v-model="newPatient.raceEthnicity" variant="underlined" color="accent"
            :items="['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 'White', 'Two or More Races', 'Other']"
            :menu-props="{ zIndex: 99999 }" hint="Optional" persistent-hint></v-select>
          <button class="align-self-end" v-if="!changeCreateAge" @click="changeCreateAge = true" v-bind="props">
            <span class="add-btn"> Do not know the dob? Add age </span>

          </button>

          <v-text-field v-if="changeCreateAge" placeholder="Age" v-model="newPatient.age" class="mt-4" color="accent"
            variant="underlined" density="compact" type="number"
            :error-messages="v$?.newPatient?.age?.$errors?.map(e => e.$message)">
            <v-icon class="text-icon" v-bind="props">mdi-calendar</v-icon>

          </v-text-field>
          <button type="button" class="align-self-end text--lighten-1" v-if="changeCreateAge"
            @click="changeCreateAge = false" v-bind="props">

            <span class="add-btn"> Do not know the age? Add dob </span>
          </button>
          <v-text-field label="Patient Notes" color="accent" class="left-aligned-hint" v-model="newPatient.diagnosis"
            variant="underlined" hint="Optional" persistent-hint></v-text-field>

          <h5 v-if="addingHistory" class="drawer-title text-start w-100">
            Input patient history
          </h5>
          <div v-if="addingHistory" class="text-h9 mt-1 text-start w-100">
            Please paste the previous patient notes to generate a clinical note based on the past patient history.
          </div>
          <v-expansion-panels v-if="addingHistory" class="mt-2 followup-section" v-model="templatePanel">
            <v-expansion-panel elevation="0" :value="true">
              <v-expansion-panel-title class="recorder-title py-4 text-start">
                {{ selectedTemplate?.title ? `Template: ${selectedTemplate.title}` : `Templates` }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-list>
                  <v-list-item v-for="(template, i) in userTemplates" :key="i" @click="selectTemplate(template)"
                    class="text-start">
                    <v-list-item-content>
                      <v-list-item-title class="d-flex align-center">
                        <v-icon left class="mr-4">mdi-file-document-outline</v-icon>
                        {{ template?.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="userTemplates.length === 0">
                    <v-list-item-content>
                      <v-list-item-title class="d-flex align-center">
                        Loading templates...
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-textarea v-if="addingHistory" class="mt-4" rows="8" row-height="24" label="Clinical notes" auto-grow
            v-model="history" clearable variant="outlined"></v-textarea>



          <div class="d-flex justify-center mt-6 mb-2">
            <v-btn color="bittersweet" elevation="0" :loading="createPatientLoading"
              @click="handleCreateAndNavigate(false)">
              <span class="btnText">Create</span>
            </v-btn>
            <v-btn class="ml-5" color="bittersweet" :loading="createPatientLoading" elevation="0"
              @click="handleCreateAndNavigate(true)"> <span class="btnText">Create and Continue</span>
            </v-btn>
          </div>
          <div class="error"> {{ createPatientError }}</div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>

  <v-navigation-drawer class="fullscreen-drawer" persistent temporary location="right" :width="800" :scrim="false"
    v-model="editDialog" max-width="500px">
    <v-card style="height: 100%; overflow-y: auto; width: 100%;">
      <v-card-title class="d-flex justify-center align-start mt-6">
        <span class="text-h5">
          Edit Patient
        </span>
        <v-btn icon class="absolute-top-right mr-4 mt-2" @click="editDialog = false" elevation="0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="d-flex flex-column border-0" style="box-shadow: none; max-width: 100% !important;"
          @submit.prevent>

          <v-text-field class="mt-8" v-model="selectedPatient.patientName" color="accent" label="Name"
            variant="underlined" density="compact"></v-text-field>
          <div class="d-flex align-center justify-center">
            <v-select label="Gender" v-model="selectedPatient.gender" variant="underlined" color="accent"
              :menu-props="{ zIndex: 99999 }"
              :items="['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other']"></v-select>
            <v-select class="ml-5" label="Pronouns" v-model="selectedPatient.pronoun" variant="underlined"
              :menu-props="{ zIndex: 99999 }" color="accent" :items="['he/him', 'she/her', 'they/them']"></v-select>
          </div>
          <v-text-field v-if="!changeEditAge" label="Date of birth" type="date" color="accent"
            v-model="selectedPatient.dob" variant="underlined" class="dob-input"></v-text-field>
          <v-select label="Race/Ethnicity" v-model="selectedPatient.raceEthnicity" variant="underlined" color="accent"
            :items="['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino', 'Native Hawaiian or Other Pacific Islander', 'White', 'Two or More Races', 'Other']"
            :menu-props="{ zIndex: 99999 }" hint="Optional" persistent-hint></v-select>

          <button type="button" class="align-self-end text--lighten-1" v-if="!changeEditAge"
            @click="changeEditAge = true" v-bind="props">

            <span class="add-btn"> Do not know the dob? Add age </span>
          </button>

          <!-- <v-btn class="white--text text--lighten-1 mr-4"
            variant="text">
            <v-icon dense class="add-btn">mdi-plus</v-icon>
            <span class="white--text add-btn"> Import history </span>
          </v-btn> -->
          <v-text-field v-if="changeEditAge" placeholder="Age" v-model="selectedPatient.age" class="mt-4" color="accent"
            variant="underlined" density="compact" type="number">
            <v-icon class="text-icon" v-bind="props" @click="changeEditAge = false">mdi-calendar</v-icon>

          </v-text-field>
          <button type="button" class="align-self-end text--lighten-1" v-if="changeEditAge"
            @click="changeEditAge = false" v-bind="props">

            <span class="add-btn"> Do not know the age? Add dob </span>
          </button>
          <v-text-field label="Patient Notes" color="accent" class="left-aligned-hint"
            v-model="selectedPatient.diagnosis" variant="underlined" hint="Optional" persistent-hint></v-text-field>

          <div class="d-flex justify-center my-6">
            <v-btn class="mdhub-btn" :loading="editPatientLoading" @click="editPatient()" elevation="0">Save</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>

  <v-navigation-drawer class="fullscreen-drawer" persistent temporary location="right" :width="800" :scrim="false"
    v-model="deleteDialog" max-width="500">
    <v-card height="100%">
      <v-card-title class="text-h5 mt-6 d-flex justify-center title-wrap">
        Are you sure you want to delete {{ deletePatient.patientName }}?
      </v-card-title>
      <v-card-item class="mb-6 text-center">
        This action permanently deletes all data associated with the patient's profile, making
        it
        non-retrievable.
      </v-card-item>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn class="mdhub-btn" variant="text" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="blue-darken-1" variant="text" @click="deletePatientSubmit()"
          :loading="deletingPatient">Delete</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
  <v-snackbar color="gray" :timeout="6000" v-model="cannotDeleteSnackbar">You are currently recording for this patient
    so
    it cannot be deleted</v-snackbar>

  <v-snackbar color="gray" :timeout="6000" v-model="notAdminSnackbar">Please contact your clinic admin to delete this
    patient.</v-snackbar>

</template>

<script setup>
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue';
import { defineProps } from 'vue';
import defaultImage from '@/assets/gender-neutral.png'
import { useRouter } from 'vue-router';
import moment from 'moment';
import NewFeatures from '@/components/NewFeatures.vue'
import { getFunctions, httpsCallable } from "firebase/functions";
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import getUser from '@/composables/getUser'
import { Timestamp } from "@firebase/firestore";
import { trackEvent, NEW_PATIENT_CREATED, NEW_PATIENT_CREATED_ERROR, IMPORTED_HISTORY_ON_CREATE_PATIENT, IMPORTED_HISTORY_ON_CREATE_PATIENT_FAILED } from '@/utilities/analyticsService';
import useDocument from '@/composables/useDocument'
import { getIsZoomApp, getZoomMeetingID } from '@/composables/configureZoom'
import AudioRecorder from '@/components/AudioRecorder.vue';
import AudioRecorderV2 from '@/components/AudioRecorderV2.vue';
import { newRecorderWhitelist } from '@/composables/isWhitelistedAccount'
import Drawer from '@/components/recorder/Drawer.vue'
import SavingSpinner from './SavingSpinner.vue';
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'
import { getUserObject } from '@/composables/getUserObject'

const canSeeNewRecorder = ref(false)
canSeeNewRecorder.value = newRecorderWhitelist()

const store = useRecorderStore();
const { patientId: currentRecordingPatientId } = storeToRefs(store)

const { user } = getUser()

const { emit } = getCurrentInstance();

const router = useRouter()
const props = defineProps({
  patients: Array,
  gettingPatients: Boolean,
});

const userTemplates = ref([])
const templatePanel = ref(true)
const selectedTemplate = ref(null)
const { isZoomApp } = getIsZoomApp();
const notInZoomMeeting = ref(false);
const createPatientError = ref('');

const userObject = ref({});

onMounted(async () => {
  if (isZoomApp.value) {
    const meetingId = await getZoomMeetingID()
    notInZoomMeeting.value = !meetingId;
  }

  const functions = getFunctions();
  const getTemplates = httpsCallable(functions, 'getTemplates');

  const result = await getTemplates({ userId: user.value.uid, history: true });

  userTemplates.value = result.data.templates;
  if (result.data.lastTemplateId) {
    selectedTemplate.value = userTemplates.value.find(t => t.id === result.data.lastTemplateId);
    templatePanel.value = false;
  }

  userObject.value = await getUserObject();
})


// TABLE
const search = ref('')
const headers = [
  { title: 'Record', align: 'center', key: 'record', sortable: false },
  { title: 'Patient', align: 'center', key: 'patientName', sortable: true, },
  { title: 'Gender', align: 'center', key: 'gender', sortable: false },
  { title: 'Age', align: 'center', key: 'dob', sortable: false },
  { title: 'DOB', align: 'center', key: 'dob', sortable: false },
  { title: 'Diagnosis', align: 'center', key: 'diagnosis' },
  { title: 'Last session', align: 'center', key: 'lastSessionDate', sortable: true, },
  { title: 'Actions', align: 'center', key: 'actions', sortable: false },
];
const filteredPatients = computed(() => {
  if (search.value) {
    return props.patients.filter(patient => patient.patientName.toLowerCase().includes(search.value.toLowerCase()))
  }
  return props.patients
});
const getInitial = (name) => {
  return name ? name.charAt(0).toUpperCase() : '';
}
const getPatientPicture = (url) => {
  return url ?? defaultImage
}
const onRowClick = (item) => {
  router.push({ name: 'PatientOverview', params: { id: item.id } })
}
const getPronouns = (gender, pronoun) => {
  let pronouns = "";
  if (pronoun) {
    return `${gender}<br>(${pronoun})`
  }
  if (gender == "Male") {
    pronouns = `${gender}<br>(He/him)`
  } else if (gender == "Female") {
    pronouns = `${gender}<br>(She/her)`
  } else if (gender == "Non-binary") {
    pronouns = `${gender}<br>(They/them)`
  } else if (gender == "Prefer not to say") {
    pronouns = `${gender}`
  } else if (gender == "Other") {
    pronouns = `${gender}`
  }
  return pronouns
}
const calculateAge = (dob) => {
  let birthDate;
  // Check if dob is a string
  if (typeof dob === "string") {
    birthDate = new Date(dob);
  } else if (dob && typeof dob.toDate === "function") {    // Check if dob is a Firebase Timestamp    
    birthDate = dob.toDate();
  }
  // If dob is neither a string nor a Timestamp, return undefined or some default value
  else {
    return 0; // or return some default value like 0
  }
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
const formattedDOB = dob => {
  if (typeof dob === 'undefined') {
    return ''
  }

  if (typeof dob === "string") {
    return dob
  } else if (typeof dob.toDate === 'function') {

    const date = dob.toDate();
    return moment(date).format('MM-DD-YYYY');
  } else {
    return ''
  }
}
const formattedLastSession = (dob) => {
  if (typeof dob === 'undefined') {
    return ''
  }

  if (typeof dob === "string") {
    return dob
  } else if (typeof dob.toDate === 'function') {
    const date = dob.toDate();
    return moment(date).format('MM-DD-YYYY - HH:mm');
  } else {
    return ''
  }
}



// CREATE PATIENT
const createDialog = ref(false);
const addingHistory = ref(false);
const changeCreateAge = ref(true);
const createPatientLoading = ref(false);
const newPatient = ref({
  patientName: '',
  gender: '',
  pronoun: '',
  dob: '',
  age: null,
  diagnosis: '',
  raceEthnicity: '',
})
const history = ref('')
watch(
  () => newPatient.value.gender,
  (newGender, _) => {
    if (!newGender) return
    let defaultPronouns;
    switch (newGender) {
      case 'Male':
        defaultPronouns = 'he/him';
        break;
      case 'Female':
        defaultPronouns = 'she/her';
        break;
      case 'Non-binary':
        defaultPronouns = 'they/them';
        break;
      default:
        defaultPronouns = null; // You could set a default or leave this empty
        break;
    }
    newPatient.value.pronoun = defaultPronouns;
  }
);
watch(() => createDialog.value, val => {
  if (!val) {
    addingHistory.value = false
    createPatientError.value = ""
    history.value = ''
    newPatient.value = {
      patientName: '',
      gender: '',
      pronoun: '',
      dob: '',
      age: 0,
      diagnosis: '',
      raceEthnicity: '',
    }
    v$.value.$reset()
  }
})

const handleCreateAndNavigate = async (navigate) => {
  v$.value.newPatient.$touch()
  if (!v$.value.$invalid) {
    createPatientLoading.value = true;

    const functions = getFunctions();
    const createPatient = httpsCallable(functions, 'createPatient');

    let res;
    let dobTimestamp;
    if (newPatient.value.dob) {
      let dobDate = new Date(newPatient.value.dob);
      const dateNow = Timestamp.now().toDate();

      dobDate.setUTCHours(dateNow.getUTCHours());
      dobDate.setUTCMinutes(dateNow.getUTCMinutes());
      dobDate.setUTCSeconds(dateNow.getUTCSeconds());

      dobTimestamp = Timestamp.fromDate(dobDate);
    } else if (newPatient.value.age || changeCreateAge.value) {
      const currentYear = new Date().getFullYear();
      const yearOfBirth = currentYear - newPatient.value.age;
      const currentDay = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const dob = new Date(yearOfBirth, currentMonth, currentDay);
      dobTimestamp = Timestamp.fromDate(dob);
    }
    const createPatientData = {
      patientName: newPatient.value.patientName,
      patientDescription: "",
      address: "",
      diagnosis: newPatient.value.diagnosis,
      userName: user.value.displayName,
      patientUrl: defaultImage,
      sessions: [],
      gender: newPatient.value.gender,
      pronoun: newPatient.value.pronoun,
      dob: dobTimestamp.toDate(),
      age: changeCreateAge.value ? parseInt(newPatient.value.age) : null,
      raceEthnicity: newPatient.value.raceEthnicity,
    };

    try {

      res = await createPatient(createPatientData)
      emit('fetchPatients')


      if (addingHistory.value && !!history.value) {
        const uploadPatientHistory = httpsCallable(functions, 'uploadPatientHistory');

        try {
          await uploadPatientHistory({
            patientId: res.data.id,
            text: history.value,
            source: 'web',
            templateId: selectedTemplate.value?.id
          });

          trackEvent(IMPORTED_HISTORY_ON_CREATE_PATIENT, { userId: user.value.uid, userEmail: user.value.email, patientId: res.data.id });
        } catch (error) {
          trackEvent(IMPORTED_HISTORY_ON_CREATE_PATIENT_FAILED, { userId: user.value.uid, userEmail: user.value.email, patientId: res.data.id });
          createPatientError.value = error.message;
          createPatientLoading.value = false;
          return;
        }


      }
    } catch (err) {
      trackEvent(NEW_PATIENT_CREATED_ERROR, { userId: user.value.uid, userEmail: user.value.email, patientName: newPatient.value.patientName, message: err, createPatientData });
      createPatientError.value = err;
      createPatientLoading.value = false;
      return;
    }

    trackEvent(NEW_PATIENT_CREATED, { userId: user.value.uid, userEmail: user.value.email, patientName: newPatient.value.patientName, createPatientData })

    createPatientLoading.value = false;
    createDialog.value = false;

    if (navigate) {
      router.push({ name: 'PatientOverview', params: { id: res.data.id } })
    }
  }
}

//EDIT PATIENT
const editDialog = ref(false);
const changeEditAge = ref(true);
const editPatientLoading = ref(false);
const selectedPatient = ref({
  patientName: '',
  gender: '',
  pronoun: '',
  dob: '',
  age: 0,
  diagnosis: '',
  raceEthnicity: '',
})
watch(
  () => selectedPatient.value.gender,
  (newGender, _) => {
    if (!newGender) return
    let defaultPronouns;
    switch (newGender) {
      case 'Male':
        defaultPronouns = 'he/him';
        break;
      case 'Female':
        defaultPronouns = 'she/her';
        break;
      case 'Non-binary':
        defaultPronouns = 'they/them';
        break;
      default:
        defaultPronouns = null; // You could set a default or leave this empty
        break;
    }
    selectedPatient.value.pronoun = defaultPronouns;
  }
);
watch(() => editDialog.value, val => {
  if (!val) {
    selectedPatient.value = {
      patientName: '',
      gender: '',
      pronoun: '',
      dob: '',
      age: 0,
      diagnosis: '',
      raceEthnicity: ''
    }
  }
})

const selectPatient = (patient) => {
  selectedPatient.value = patient;
  editDialog.value = true;
}
const selectTemplate = async (template) => {
  templatePanel.value = false;
  selectedTemplate.value = template;
}
const editPatient = async () => {
  editPatientLoading.value = true;

  let dobTimestamp
  const age = +selectedPatient.value.age
  if (age >= 0 && changeEditAge.value) {
    // If age is provided, calculate dob as January 2 of the corresponding year
    const currentYear = new Date().getFullYear();
    const yearOfBirth = currentYear - selectedPatient.value.age;

    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth();
    const dob = new Date(yearOfBirth, currentMonth, currentDay);
    dobTimestamp = Timestamp.fromDate(dob);
  } else if (selectedPatient.value.dob) {
    // If dob is provided, use it   
    // Extract year, month, and day from the date of birth
    const dateOfBirth = new Date(selectedPatient.value.dob);
    const yearOfBirth = dateOfBirth.getUTCFullYear();
    const monthOfBirth = dateOfBirth.getUTCMonth();
    const dayOfBirth = dateOfBirth.getUTCDate();

    // Create a new Date object with the extracted components
    const dob = new Date(yearOfBirth, monthOfBirth, dayOfBirth);
    dobTimestamp = Timestamp.fromDate(dob);
  }

  const { error, updateDocument } = useDocument('patients', selectedPatient.value.id);
  const res = await updateDocument({
    patientName: selectedPatient.value.patientName,
    gender: selectedPatient.value.gender || '',
    pronoun: selectedPatient.value.pronoun || '',
    dob: dobTimestamp,
    age: changeEditAge.value ? age : null,
    diagnosis: selectedPatient.value.diagnosis || null,
    raceEthnicity: selectedPatient.value.raceEthnicity || '',
  })


  editPatientLoading.value = false;
  if (!error.value) {
    editDialog.value = false;
  }
}

// DELETE PATIENT
const deleteDialog = ref(false);
const deletingPatient = ref(false);
const deletePatient = ref({
  patientName: '',
  gender: '',
  pronoun: '',
  dob: null,
  age: 0,
  diagnosis: '',
});
const cannotDeleteSnackbar = ref(false);
const notAdminSnackbar = ref(false);
const deleteItem = (item) => {
  if (item.id === currentRecordingPatientId.value) {
    cannotDeleteSnackbar.value = true;
    return
  }

  if (item.clinicId && !userObject.value.isAdmin) {
    notAdminSnackbar.value = true;
    return
  }

  deletePatient.value = item;
  deleteDialog.value = true;
}
const deletePatientSubmit = async () => {
  deletingPatient.value = true;
  const functions = getFunctions();
  const deletePatientByPatientId = httpsCallable(functions, 'deletePatientByPatientId');
  try {
    await deletePatientByPatientId(deletePatient.value.id)
    emit('fetchPatients')
  } catch (error) {
    console.log(`Error deleting Session and Audio ${error}`)
  }
  deletingPatient.value = false;
  deleteDialog.value = false;
}

// VALIDATION

const atLeastOneRequired = helpers.withParams(
  { type: 'atLeastOneRequired' },
  function (value, parentVm) {
    return !!newPatient.value.age || !!newPatient.value.dob
  }
)
const rules = {
  newPatient: {
    patientName: { required },
    gender: { required },
    pronoun: { required },
    dob: { required: helpers.withMessage('Value is required', atLeastOneRequired) },
    age: { required: helpers.withMessage('Value is required', atLeastOneRequired) },
  },
}


const v$ = useVuelidate(rules,
  { newPatient },
)
</script>


<style scoped>
.rounded-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #FFF;
}

.rounded-table {
  border-radius: 10px;
  border: 1px solid rgba(224, 224, 224, 0.5);
}

.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.btnText {
  text-decoration: none;
  text-transform: none;
  color: white;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #64748b;
  height: 100%;
  width: 100%;
}

.v-field--variant-solo-filled .v-field__overlay {
  opacity: 0 !important;
}

::v-deep .v-field__overlay {
  opacity: 0.01 !important;
}


::v-deep .v-text-field:focus-within .v-field__overlay {
  opacity: 0.05 !important;
}

.text-icon {
  position: absolute;
  right: 0;
}

.align-self-end {
  align-self: self-end !important;
}

.dob-input ::v-deep input {
  display: initial;
}

.white--text {
  color: white;
  text-transform: none;
}

.add-btn {
  background-color: transparent !important;
  color: var(--bittersweet);
  text-transform: none !important;
  width: auto;
}

.hand-cursor {
  cursor: pointer;
}

.followup-section {
  background-color: white;
  border-radius: 4px;
  padding: 4px;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.error {
  color: var(--warning);
}

/* ::v-deep .v-field--active input {
  opacity: 0.01 !important;
} */
</style>