<template>
    <div class="ma-4">
        <h5 class="text-h5 mb-0 text-left">AI Care Coordinator </h5>
        <p class="text-left text-body-2">Review and manage patient intake interactions handled by Sarah.</p>
    </div>
    <div v-if="fetchingInteractions" class="d-flex flex-column align-center py-8 ">
        <SavingSpinner :label="'Fetching phone calls'" style="position:relative" />
    </div>
    <div style="text-align:left" class="d-flex flex-column align-center py-8"
        v-if="!chatBots.length && !fetchingInteractions">No active conversations at
        the
        moment
    </div>
    <div v-else-if="chatBots.length > 0" class="d-flex chat-app-container">

        <div class="chat-list pr-4" style="width: 40%;">


            <v-list class="chat-list-items rounded-border">
                <v-list-item v-for="(bot, index) in chatBots" @click="selectChat(bot, index)"
                    :class="{ 'selected-chat': selectedChatBot && selectedChatBot[0]?.conversationId === bot?.id }"
                    class="mb-2 ">
                    <template v-slot:prepend>
                        <v-avatar color="grey-lighten-1" size="48">
                            <v-icon icon="mdi-phone"></v-icon>
                        </v-avatar>
                    </template>
                    <v-list-item-title style="text-align: left;">{{ formatDate(bot.conversationDetails.startedAt)
                        }}</v-list-item-title>




                    <template v-slot:append>
                        <v-list-item-subtitle v-if="bot.live" style="color:green">
                            <v-icon color="green" size="12" class="pulsing-icon">mdi-circle</v-icon>
                            LIVE
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="userObject?.isAdmin">
                            <v-btn color="grey-lighten-1" icon="mdi-delete" variant="text"
                                @click.stop="selectConversationToDelete(bot)"></v-btn>
                        </v-list-item-subtitle>
                    </template>
                </v-list-item>
            </v-list>
        </div>



        <div class="chat-interface pl-4" style="width: 60%;">
            <v-card class="rounded-border" elevation="0">
                <v-tabs v-model="tab" slider-color="var(--bittersweet)" class="patient-chart-options">
                    <v-tab value="conversation">Conversation</v-tab>
                    <v-tab value="summary">Report</v-tab>
                    <v-btn width="200" :loading="generatingSummary" v-if="canRegenerate" class="mb-3"
                        @click="regenerateSummary(selectedChatBot[0]?.conversationId)">generate
                        again</v-btn>
                </v-tabs>
                <v-window class="window pa-4 mt-4" v-model="tab">
                    <v-window-item value="conversation">
                        <div v-if="selectedChatBot" id="chat" class="chat-messages chat-container">
                            <template v-for="message in selectedChatBot">
                                <div :class="['message', message.speaker === 'ai-agent' ? 'bot-message' : 'user-message']"
                                    v-if="message.text">
                                    <v-avatar size="32" class="mr-2 bordered-avatar"
                                        :class="{ 'ml-2': message.speaker == 'Patient' }">
                                        <v-icon :color="message.speaker === 'ai-agent' ? '#B0B0B0' : '#B0B0B0'"
                                            :icon="message.speaker === 'ai-agent' ? 'mdi-phone' : 'mdi-account'">
                                        </v-icon>
                                    </v-avatar>
                                    <div class="message-content">
                                        <p class="message-sender">{{ message.speaker === 'ai-agent' ? 'Sarah' :
                                            'Patient' }}</p>
                                        <p class="message-text">{{ stripTags(message.text) }}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </v-window-item>
                    <v-window-item value="summary" v-if="selectedChatBot">
                        <div class="text-left pl-8 summary-text" v-html="selectedChatBot[0]?.summary"></div>
                    </v-window-item>
                </v-window>
            </v-card>

        </div>

        <v-navigation-drawer v-model="deleteDrawer" temporary location="right" :width="800">
            <div class="d-flex flex-column align-start pa-3" v-if="deleteBot">
                <h4 class="text-left">Are you sure you want to delete the conversation made on {{
                    formatDate(deleteBot?.conversationDetails?.startedAt) }}?</h4>
                <div class="mt-5">
                    <v-btn elevation="0" width="auto" class="mdhub-btn" @click="deleteDrawer = false">
                        Cancel</v-btn>
                    <v-btn elevation="0" width="auto" style="text-transform: none;" class="ml-2" :loading="deleting"
                        @click="deleteConversation">Delete
                        Conversation</v-btn>
                </div>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watchEffect } from 'vue'
import moment from 'moment'
import botConversations from '@/composables/getBotConversations.js';
import { Timestamp } from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getUserObject } from '@/composables/getUserObject'
import { regenerateConversationSummaryWhitelist } from '@/composables/isWhitelistedAccount'
import SavingSpinner from '@/components/SavingSpinner.vue'
const canRegenerate = ref(false)
canRegenerate.value = regenerateConversationSummaryWhitelist()

const chatBots = ref([])
const fetchingInteractions = ref(false);
const selectedChatBot = ref(null)
const selectedIndex = ref(0)

const stripTags = (text) => {
    // Return text without tags
    return text ? text.replace(/<\/?[^>]+(>|$)/g, "") : '';
}

const selectChat = (conversation, index) => {
    selectedIndex.value = index;
    selectedChatBot.value = mapAndSortConversation({ id: conversation.id, interaction: conversation.interaction, summary: conversation.conversationDetails.summary });
    if (conversation.live) {
        scrollToTop();
    }
    // think about going always at the top - do we need this?
}
const mapAndSortConversation = (conversation) => {
    return conversation.interaction.map((message) => {
        return {
            conversationId: conversation.id,
            speaker: message.speaker === 'ai-agent' ? 'ai-agent' : 'Patient',
            text: message.speaker === 'ai-agent' ? message.label : message.text,
            interactionCount: message.interactionCount,
            timestamp: message.timestamp,
            summary: conversation.summary
        }
    }).sort((a, b) => a.interactionCount - b.interactionCount || a.timestamp - b.timestamp)
}

const formatDate = (date) => {
    return moment(new Timestamp(date.seconds, date.nanoseconds).toDate()).format('dddd, MMMM Do, YYYY HH:mm')
}

const scrollToTop = () => {
    nextTick(() => {
        let chat = document.getElementById('chat');;
        chat.scrollTop = chat.scrollHeight
    });
};

watchEffect(() => {
    const { clinicConversations, loading } = botConversations;
    fetchingInteractions.value = loading.value;
    chatBots.value = clinicConversations.value.sort((a, b) => b.conversationDetails.startedAt - a.conversationDetails.startedAt).map(bot => ({
        ...bot,
        live: !bot.conversationDetails.endedAt
    }));

    if (chatBots.value.length > 0) {
        selectChat(chatBots.value[selectedIndex.value], selectedIndex.value)
    }
})

const tab = ref('conversation')
const generatingSummary = ref(false)
const functions = getFunctions();

const regenerateSummary = async (id) => {
    if (generatingSummary.value) return;
    generatingSummary.value = true;
    const generateConversationSummary = httpsCallable(functions, 'generateConversationSummary');
    await generateConversationSummary({ id })
    generatingSummary.value = false;
}

const userObject = ref(null);
onMounted(async () => {
    userObject.value = await getUserObject();
})

const deleteBot = ref(null)
const deleteDrawer = ref(false)
const selectConversationToDelete = (bot) => {
    deleteBot.value = bot
    deleteDrawer.value = true
}
const deleting = ref(false)
const deleteConversation = async () => {
    deleting.value = true
    const deleteConversationFunction = httpsCallable(functions, 'deleteConversation');
    try {
        await deleteConversationFunction({ id: deleteBot.value.id })
    } catch (e) {
        console.error(e)
    }
    finally {
        deleting.value = false
        deleteDrawer.value = false
    }
}

</script>

<style scoped>
.summary-text {
    line-height: 2;
}

.chat-list {
    overflow-y: auto;
    height: 70vh;
    padding-left: 16px;
}

.chat-list-items {
    height: 70vh;
    overflow-y: scroll;

}

.window {
    height: 70vh;
    overflow-y: auto;
}

.chat-interface {
    height: 100%;
    padding-right: 16px;
}

.chat-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-y: scroll;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
}

.message {
    display: flex;
    align-items: flex-start;
    max-width: 80%;
}

.bot-message {
    align-self: flex-start;
}

.user-message {
    align-self: flex-end;
    flex-direction: row-reverse;
}

.message-content {
    background-color: #e8edf2;
    text-align: left;
    border-radius: 14px;
    padding: 8px 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.bordered-avatar {
    border: 1px solid;
    border-color: #B0B0B0;
}

.bot-message .bordered-avatar {
    border-color: #B0B0B0;
}

.user-message .message-content {
    background-color: white;
    border: 0.5px solid #B0B0B0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.message-sender {
    font-weight: bold;
    margin-bottom: 4px;
}

.message-text {
    margin: 0;
}

.selected-chat {
    background-color: #e8edf2;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.75;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0.75;
    }
}

.pulsing-icon {
    animation: pulse 1.5s infinite;
}

.patient-chart-options ::v-deep .v-tab--selected span {
    color: var(--bittersweet);
    font-size: medium;

}
</style>