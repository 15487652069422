<template>
    <OnboardingQuestionnaireClient v-if="!submitted" :error="error" :userName="userName" @handleSubmit="handleSubmit" />
    <div v-else class="d-flex flex-column align-center">

        <div v-if="loading" class="pt-4">
            {{ loadingText }}
        </div>

        <div v-else-if="!!questionnaire && submitted">
            <div class="d-flex flex-column pt-10">
                <v-timeline side="end" :line-thickness="isMobile ? 0 : 2">
                    <v-timeline-item size="20" dot-color='var(--bittersweet)' fill-dot :hide-dot="isMobile"
                        :class="{ 'no-padding': isMobile }">
                        <v-card elevation="0" class="mb-2 viewWidth rounded-card">
                            <v-card-text class="">
                                <h2 class="mb-2 text-left">{{ questionnaire?.title }}
                                </h2>
                                <p class="description mt-5 text-left mb-0">{{ questionnaire?.description }}
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item v-for="(item, i) in questionnaire?.schema" :key="item.id" size="20"
                        dot-color='var(--bittersweet)' :hide-dot="item.type !== 'input_title' || isMobile" fill-dot
                        :class="{ 'no-padding': isMobile }">

                        <v-card class="viewWidth rounded-card" elevation="0" :link="false">
                            <v-card-text :class="{ 'required-field-border': errorMessage(item.id) }">
                                <Items :id="item.id" :item="item" :error="errorMessage(item.id)" />
                            </v-card-text>

                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </div>

            <v-btn class="my-4 mdhub-btn" @click="submit" :loading="loading" elevation="0">Submit</v-btn>
        </div>

        <div v-else class="mt-12">{{ error }}</div>
    </div>
    <v-snackbar v-model="snackbar" color="gray" elevation="24">
        {{ errorMessageText }}

        <template v-slot:actions>
            <v-btn @click="scrollToItem(errorIds[0])" class="mdhub-btn">
                Go to next question
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import Items from '@/components/questionnaire-client/Items.vue';
import { decryptToken } from '@/composables/useToken';
import { useRouter } from 'vue-router';
import { trackEvent, QUESTIONNAIRE_SUBMITTED_BY_PATIENT, QUESTIONNAIRE_SUBMITTED_BY_PATIENT_FAILED } from '@/utilities/analyticsService';
import { watchDebounced } from '@vueuse/core'
import OnboardingQuestionnaireClient from './OnboardingQuestionnaireClient.vue';
import { firebaseConfig } from '@/firebase/config'

const router = useRouter()

const functions = getFunctions();
const questionnaire = ref(null);
const loading = ref(false);
const snackbar = ref(false);
let patientQuestionnaireId = ''
const questionnaireId = ref('')
const error = ref(null)
const errorMessageText = ref('');
const userAgent = navigator.userAgent;
const isMobile = /Mobile|Android|iOS/i.test(userAgent);
const loadingText = ref('');
const userName = ref('');
const submitted = ref(false);

const age = ref('');
const dob = ref(null);
const gender = ref('');
const pronoun = ref('');
const patientName = ref('');
const apiKey = ref(firebaseConfig.functionApiKey)
onMounted(async () => {
    try {
        loading.value = true
        const data = await decryptToken(window.location);
        // this means that the questionnaire has been accessed via a reusable link 
        userName.value = data.userName;
        loading.value = false;
        const rawRequest = {
            headers: {
                'x-api-key': apiKey.value,
            }
        }
        if (!data.patientQuestionnaireId) {
            questionnaireId.value = data.questionnaireId
            const getQuestionnaire = httpsCallable(functions, 'getQuestionnaire');
            const result = await getQuestionnaire({ questionnaireId: questionnaireId.value, rawRequest });
            questionnaire.value = result.data.questionnaire;
            fetchUnfinishedQuestionnaire(questionnaireId.value);
            loading.value = false
            return;
        }
        const getClientQuestionnaire = httpsCallable(functions, 'getClientQuestionnaire');
        patientQuestionnaireId = data.patientQuestionnaireId
        const result = await getClientQuestionnaire({ patientQuestionnaireId: data.patientQuestionnaireId, rawRequest })
        questionnaire.value = result.data.questionnaire
        fetchUnfinishedQuestionnaire(patientQuestionnaireId);

        loading.value = false
    } catch (err) {
        loading.value = false
        error.value = err.message
    }
})

const fetchUnfinishedQuestionnaire = (id) => {
    const unfinished = localStorage.getItem(`patient-questionnaire:${id}`)
    if (unfinished) {
        questionnaire.value = JSON.parse(unfinished);
        submitted.value = questionnaire.value.onboarded;
        loading.value = false
        return
    }
}

const errorIds = ref([])
const checkRequiredFields = () => {
    questionnaire.value.schema.forEach(item => {
        if (item.type === 'input_number' && item.response) {
            if (!isNumeric(item.response)) {
                errorIds.value.push({ id: item.id, message: 'This field must be a number' })
            }
        }
        if (item.required && (!item.response || item.response?.length === 0)) {
            errorIds.value.push({ id: item.id, message: 'This field is required' })
        }

        if (item.required && item.type === 'input_list') {
            item.response.forEach(row => {
                if (row.some(cell => !cell)) {
                    errorIds.value.push({ id: item.id, message: 'This field is required' })
                }
            })
        }

        if (item.required && item.type === 'input_likert') {
            item.response.forEach(res => {
                if (!res.response) {
                    errorIds.value.push({ id: item.id, message: 'This field is required' })
                }
            })
        }
    })
}

const submit = async () => {
    checkRequiredFields()

    if (errorIds.value.length > 0) {
        return scrollToItem(errorIds.value[0])
    }
    loadingText.value = 'Please wait while the questionnaire is being submitted. This process may take up to two minutes.'
    loading.value = true
    questionnaire.value.schema.forEach(item => {
        if (item.type === 'input_list') {
            item.response = item.columns.map((column, index) => ({
                col: column,
                responses: item.response.map(row => row[index])
            }));
        }
        if (item.type === 'input_number') {
            if (!item.response) {
                item.response = ''
            }
            else if (!!item.response && item?.response % 1 === 0) {
                item.response = item.response
            } else {
                item.response = parseFloat(item.response).toFixed(2)
            }
        }
        if (item.type === 'input_single') {
            if (!item.requireComment?.includes(item.response)) {
                item.comment = ''
            }
        }
    })

    try {
        // when this is not present we can assume that the questionnaire was accessed via a shareable link
        const submitted = !patientQuestionnaireId ? await submitQuestionnaireFromShareableUrl() : await submitQuestionnaireFromEmail()
        trackEvent(QUESTIONNAIRE_SUBMITTED_BY_PATIENT, { userId: questionnaire.value.userId, patientQuestionnaireId, title: questionnaire.value.title, patientEmail: questionnaire.value.patientEmail, submitted })
        router.push({ name: 'QuestionnaireSubmitted', query: { status: 'Questionnaire submitted successfully' } })
    } catch (err) {
        trackEvent(QUESTIONNAIRE_SUBMITTED_BY_PATIENT_FAILED, { userId: questionnaire.value.userId, patientQuestionnaireId, title: questionnaire.value.title, patientEmail: questionnaire.value.patientEmail, err })
        router.push({ name: 'QuestionnaireSubmitted', query: { status: err.message } })
    } finally {
        loading.value = false
        loadingText.value = ''
    }


}

const submitQuestionnaireFromShareableUrl = async () => {
    const submitQuestionnaireWithoutEmail = httpsCallable(functions, 'submitQuestionnaireWithoutEmail');
    const { title, type, userId, description } = questionnaire.value
    // here we also need to take the values from the localstorage too
    const filledPatiendData = JSON.parse(localStorage.getItem(`patient-questionnaire:${questionnaireId.value}`))
    console.log(filledPatiendData)
    const payload = {
        title,
        type,
        userId,
        description,
        patientEmail: questionnaire.value.patientEmail,
        age: age.value,
        dob: dob.value,
        gender: gender.value,
        pronoun: pronoun.value,
        patientName: patientName.value
    }

    patientQuestionnaireId = await submitQuestionnaireWithoutEmail({ questionnaireId: questionnaireId.value, schema: questionnaire.value.schema, ...payload })
    localStorage.removeItem(`patient-questionnaire:${questionnaireId.value}`)
    questionnaireId.value = ''
    return true;
}

const submitQuestionnaireFromEmail = async () => {
    const submitQuestionnaire = httpsCallable(functions, 'submitQuestionnaire');
    await submitQuestionnaire({ patientQuestionnaireId, schema: questionnaire.value.schema })
    localStorage.removeItem(`patient-questionnaire:${patientQuestionnaireId}`);
    return true;
}

watch(questionnaire, (newV) => {

    if (errorIds.value.length > 0) {
        errorIds.value = []
        checkRequiredFields()
        snackbar.value = true;
        errorMessageText.value = `${errorIds.value.length} more required answers have not been answered yet.`;
    }

}, { deep: true })

// do we need to change/adjust this to meet the non-email submission?
watchDebounced(questionnaire, () => {
    // take into consideration that the patientQuestionnaireId is not present when the questionnaire is accessed via a shareable link
    localStorage.setItem(`patient-questionnaire:${patientQuestionnaireId || questionnaire.value.id}`, JSON.stringify({
        ...questionnaire.value,
        onboarded: submitted.value,
    }))
}, { deep: true, debounce: 1000, maxWait: 5000 })

const errorMessage = (id) => {
    const error = errorIds.value.find(e => e.id === id)
    if (error) {
        return error.message
    }
    return ''
}

const scrollToItem = (item) => {
    nextTick(() => {
        const element = document.getElementById(item.id)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        })
    })

}

const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

const handleSubmit = (data) => {
    if (error.value) {
        return;
    }

    questionnaire.value.patientEmail = data.email;
    age.value = data.age;
    dob.value = data.dob;
    gender.value = data.gender;
    pronoun.value = data.pronoun;
    patientName.value = data.name;

    questionnaire.value.schema.forEach((field) => {
        if (field.cannotEdit) {
            switch (field.title) {
                case 'Age':
                    field.response = data.age;
                    break;
                case 'Patient name':
                    field.response = data.name;
                    break;
                case 'Gender':
                    field.response = data.gender;
                    break;
                case 'Pronouns':
                    field.response = data.pronoun;
                    break;
                default:
                    field.response = '';
                    break;
            }
        }
    });

    submitted.value = true;
}

</script>


<style scoped>
.selectedCard {
    border-left: 5px solid var(--bittersweet);
}

.absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.btnText {
    text-decoration: none;
    text-transform: none;
    color: white;
}

.form-width {
    max-width: 100% !important;
}

.send-btn {
    align-self: end;
}

.viewWidth {
    width: 70vw;
}

.rounded-card {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

::v-deep .v-timeline-item__body {
    width: 100% !important;
    padding: 0 0 0 15px !important;

}

.required-field-border {
    border-radius: 10px;
    border: 1px solid var(--bittersweet);
}

::v-deep .no-padding .v-timeline-item__body {
    padding-left: 0 !important
}

.description {
    font-size: 19px;
    line-height: 33px;
}
</style>