<template>
    <div>
        <div v-if="selectable" class="mx-4">
            <h5 class="section-header">Questionnaire</h5>
            <v-expansion-panels class="questionnaire-section" v-model="questionnairePanel">
                <v-expansion-panel elevation="0" :value="true">
                    <v-expansion-panel-title class="questionnaire-title text-start">

                        {{ selectedQuestionnaire?.title ? `${selectedQuestionnaire.title}` : `Select a questionnaire
                        submitted by
                        ${patient.patientName}. It will be used to create a more accurate clinical note` }}.

                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-list>
                            <v-list-item v-for="(questionnaire, i) in selectableQuestionnaires" :key="i"
                                @click="selectQuestionnaire(questionnaire)" class="text-start">
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex align-center">
                                        <v-icon left class="mr-4">mdi-text-box-edit-outline</v-icon>
                                        {{ questionnaire?.title }} &nbsp;|&nbsp;
                                        {{ questionnaire?.submittedAt ? formatDate(questionnaire.submittedAt) : '-' }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="patientQuestionnaires.length === 0">
                                <div v-if="!loading" style="text-align: left;">
                                    <p>
                                        No questionnaires sent to {{ patient.patientName }}
                                    </p>
                                </div>
                                <v-list-item-content v-else>
                                    <v-list-item-title class="d-flex align-center">
                                        <SavingSpinner :label="`Fetching questionnaires for ${patient.patientName}`"
                                            v-if="loading" style="position:relative;" />
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-card v-else class="mt-3 mb-12 sessions-section-card" elevation="0">

            <div class="d-flex mt-4 mx-5 justify-space-between">
                <div class="text-h6">Patient Questionnaires</div>

                <v-btn @click="emailDrawer = true" class="white--text text--lighten-1 text-decoration-none"
                    variant="text">
                    <span class="white--text add-btn">Send</span>
                </v-btn>
            </div>

            <v-data-table width="500px" class="rounded-table" :headers="headers" :items="patientQuestionnaires"
                :hover="true" :loading="loading">
                <template v-slot:item="{ item }">
                    <tr @click="selectQuestionnaire(item)" class="hand-cursor">
                        <td class="text-left">
                            <template v-if="item.submitted">
                                <template v-if="item.reviewed">
                                    Reviewed
                                </template>
                                <template v-else class="d-flex align-center justify-center">
                                    <v-badge inline dot color="var(--electric-blue)"></v-badge>
                                    <span class="ml-1">Ready for Review</span>
                                </template>
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <td class="text-left">{{ item.title }}</td>
                        <td class="text-left">{{ mapQuestionnaireType(item.type) }}</td>
                        <td class="text-left">{{ formatDate(item.createdAt) }}</td>
                        <td class="text-left">{{ item.submittedAt ? formatDate(item.submittedAt) : (item.submitted ?
                            'Yes' :
                            'No') }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-navigation-drawer v-if="!selectable" v-model="emailDrawer" temporary location="right" :width="800"
            :scrim="false">
            <QuestionnaireEmail :patient="patient" :selectQuestionnaire="true" @emailSent="emailDrawer = false;" />
        </v-navigation-drawer>
    </div>

</template>

<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, defineProps, onMounted, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'
import QuestionnaireEmail from "./QuestionnaireEmail.vue";
import moment from 'moment'
import { Timestamp } from "@firebase/firestore";
import SavingSpinner from '@/components/SavingSpinner.vue';

const router = useRouter();
const { emit } = getCurrentInstance();
const props = defineProps({
    patient: Object,
    selectable: Boolean
})

const headers = [
    { title: 'Status', align: 'start', key: 'reviewed' },
    { title: 'Title', align: 'start', key: 'title' },
    { title: 'Type', align: 'start', key: 'type' },
    { title: 'Sent', align: 'start', key: 'createdAt' },
    { title: 'Submitted', align: 'start', key: 'shared' },
    // { title: 'Actions', align: 'start', key: 'actions' }
]

const loading = ref(false)
const patientQuestionnaires = ref([])
const selectable = ref(false);
const questionnairePanel = ref(0)
const selectedQuestionnaire = ref(undefined)
const selectableQuestionnaires = ref([]);

onMounted(() => {
    selectable.value = props.selectable;
    loading.value = true
    const functions = getFunctions();
    const getPatientQuestionnaires = httpsCallable(functions, 'getPatientQuestionnaires');
    getPatientQuestionnaires({ patientId: props.patient.id })
        .then((result) => {
            patientQuestionnaires.value = result.data.questionnaires
            selectableQuestionnaires.value = result.data.questionnaires.filter(q => q.submitted).sort((a, b) => {
                return new Date(b.createdAt._seconds * 1000) - new Date(a.createdAt._seconds * 1000)
            })
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            loading.value = false
        })
})


const selectQuestionnaire = (questionnaire) => {
    if (selectable.value) {
        questionnairePanel.value = false;
        selectedQuestionnaire.value = questionnaire;
        emit('selected-questionnaire', questionnaire.id)
        return;
    }
    router.push({ name: 'PatientQuestionnaire', params: { patientId: props.patient.id, patientQuestionnaireId: questionnaire.id } })
}

const emailDrawer = ref(false)
const formatDate = (date) => {
    return moment(new Timestamp(date._seconds, date._nanoseconds).toDate()).format('MM-DD-YYYY HH:mm')
}

const mapQuestionnaireType = (type) => {
    switch (type) {
        case 'questionnaire_intake':
            return 'Intake';
        case 'questionnaire_follow_up':
            return 'Follow Up';
        case 'questionnaire_screening':
            return 'Screening';
        default:
            return 'Unknown';
    }
}

</script>
<style scoped>
.hand-cursor {
    cursor: pointer;
}


.add-btn {
    background-color: transparent !important;
    color: var(--bittersweet);
    text-transform: none !important;
    width: auto;
}

.sessions-section {
    border-radius: 14px;
    padding: 4px;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0px;
}

.sessions-section-card {
    font-size: 1rem;
    text-align: left;
    position: relative;
    background-color: white;
    padding: 4px;
    margin-top: 3.5%;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.section-header {
    font-weight: 500;
    text-align: left;
}


.questionnaire-section {
    background-color: white;
    border-radius: 4px;
    padding: 4px;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.questionnaire-title {
    font-size: 15px;
    line-height: 1.3em;
}

.questionnaire-section button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}
</style>
