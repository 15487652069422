<template>
    <div class="d-flex flex-column align-start py-4">
        <v-autocomplete v-model="doctorId" :loading="gettingDoctors" class="select" label="Select a team member"
            :items="doctors" item-title="email" item-value="userId" clearable>
            <template v-slot:no-data>
                <div class="pa-3" v-if="gettingDoctors">
                    Loading users...
                </div>
                <div class="pa-3" v-else>No data available</div>
            </template>
        </v-autocomplete>
        <v-data-table class="notes-section" :hover="true" :loading="loading" :headers="headers" :items="filteredInbox">
            <template v-slot:top>
                <v-toolbar flat color="#FFF" class="rounded-toolbar">
                    <v-text-field class="ml-6 custom-bg" v-model="search" prepend-icon="mdi-magnify" label="Search"
                        base-color="#fff" single-line hide-details variant="solo-filled" clearable flat></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:item="{ item }">

                <tr @click="rowClick(item)">
                    <td class="text-center">
                        <div>
                            <v-icon v-if="item.status == statusEnum.Reviewed" color="green"
                                size="12">mdi-circle</v-icon>
                            <v-icon v-else-if="item.status == statusEnum.ReadyForReview" color="rgb(255, 114, 96)"
                                size="12">mdi-circle-outline</v-icon>
                            <v-icon v-else-if="item.status == statusEnum.AwaitingSubmission" color="var(--melon)"
                                size="12">mdi-circle-outline</v-icon>
                            <v-icon v-else color="green" size="12">mdi-circle</v-icon>
                            {{ item.status }}
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column text-center align-center">
                            <span>{{ item.patient?.patientName }}</span>
                            <span>{{ item.patient?.email }} </span>
                        </div>
                    </td>
                    <td class="text-center">
                        {{ getFormattedDate(item.createdAt) }}
                    </td>
                    <td class="text-center">
                        <v-chip v-if="item.type === 'Questionnaire'" color="var(--melon)"
                            variant="flat">Questionaire</v-chip>
                        <v-chip v-else color="var(--mint-macaron)" variant="flat">Note</v-chip>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import getUser from '@/composables/getUser'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Timestamp } from "@firebase/firestore";
import { useRouter } from 'vue-router';

const { user } = getUser()
const functions = getFunctions();
const router = useRouter()

const doctors = ref([])
const gettingDoctors = ref(false)
const doctorId = ref(null)

onMounted(async () => {
    gettingDoctors.value = true
    const getClinicUsers = httpsCallable(functions, 'getClinicUsers');
    const res = await getClinicUsers();

    doctors.value = res.data.users
    gettingDoctors.value = false
})

watch(doctorId, (newVal) => {
    if (!newVal) {
        inbox.value = { questionnaires: [], sessions: [] }
        return
    }
    getDoctorInbox(newVal)
})

const loading = ref(false)
const inbox = ref({ questionnaires: [], sessions: [] })

const getDoctorInbox = async (doctorId) => {
    loading.value = true
    const getClinicInboxByUser = httpsCallable(functions, 'getClinicInboxByUser');
    const res = await getClinicInboxByUser({ doctorId })
    console.log(res)
    inbox.value.questionnaires = res.data.questionnaires
    inbox.value.sessions = res.data.sessions
    loading.value = false
}

const sortedCombinedInbox = computed(() => {
    const combinedInbox = [
        ...inbox.value.questionnaires
            .map(questionnaire => ({
                ...questionnaire,
                type: 'Questionnaire',
                date: new Timestamp(questionnaire.createdAt._seconds, questionnaire.createdAt._nanoseconds),
                status: questionnaire.reviewed ? 'Reviewed' : questionnaire.submitted ? 'Ready for Review' : 'Awaiting Submission',
            })),
        ...inbox.value.sessions
            .map(session => ({
                ...session,
                type: 'Session',
                date: new Timestamp(session.createdAt._seconds, session.createdAt._nanoseconds),
                status: session.notesStatus
            }))
    ].sort((a, b) => b.date - a.date)
    return combinedInbox
})

const search = ref('')
const filteredInbox = computed(() => {
    if (!search.value) return sortedCombinedInbox.value

    const searchTerm = search.value.toLowerCase()
    return sortedCombinedInbox.value.filter(item => {
        return (
            (item.patient?.patientName && item.patient?.patientName.toLowerCase().includes(searchTerm)) ||
            (item.patient?.email && item.patient?.email.toLowerCase().includes(searchTerm)) ||
            (item.notesStatus && item.notesStatus.toLowerCase().includes(searchTerm)) ||
            (item.type && item.type.toLowerCase().includes(searchTerm)) ||
            (item.id && item.id.toLowerCase().includes(searchTerm))
        )
    })
})


//constants
const statusEnum = {
    ReadyForReview: 'Ready for Review',
    Reviewed: 'Reviewed',
    AwaitingSubmission: 'Awaiting Submission',
}

const headers = [
    { title: 'Status', align: 'center', value: 'status' },
    { title: 'Patient', align: 'center', value: 'patient' },
    { title: 'Time', align: 'center', value: 'date' },
    { title: 'Type', align: 'center', value: 'type' },
]

const getFormattedDate = (date) => {
    return new Date(date._seconds * 1000)
        .toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        })
}

const rowClick = (item) => {
    if (item.type === 'Questionnaire') return
    if (!item.patient?.clinicId) return
    router.push({ name: 'SessionDetails', params: { patientId: item.patientId, id: item.id } })
}

</script>

<style scoped>
.select {
    width: 330px !important;
}

.notes-section {
    border-radius: 14px;
    padding: 4px;
    line-height: 1.5;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
</style>
