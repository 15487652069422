<template>
    <v-sheet class="d-flex flex-column justify-center align-center mt-4" color="#f9fbfd">
        <v-card class="mdhub-card elevation-0" width="100%" height="100%">
            <v-card-title class="left-align mb-5 mt-7 ml-6"> Clinic details</v-card-title>
            <div class="left-align mb-5 mt-7 ml-6">
                <v-text-field v-if="userObject" type="text" required label="Clinic name" placeholder="Clinic name"
                    v-model="clinicName" class="ml-4 mr-4" color="accent" variant="underlined"
                    @update:modelValue="handleSaveDebounced" :readonly="true" />
                <v-text-field v-if="userObject" type="text" required placeholder="Address" v-model="clinicAddress"
                    class="ml-4 mr-4" color="accent" variant="underlined" label="Address" density="compact"
                    :readonly="true" />
                <v-text-field v-if="userObject" type="text" required label="Seats" placeholder="Seats"
                    v-model="clinicSeats" class="ml-4 mr-4" color="accent" variant="underlined" density="compact"
                    :readonly="true" />
            </div>
            <v-progress-circular v-if="isPendingSave" indeterminate color="primary"></v-progress-circular>
        </v-card>
    </v-sheet>
</template>

<script>

import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import { getUserCollection } from '@/composables/userService';
import { ref } from 'vue';
import useDocument from '@/composables/useDocument'

import getDocument from '@/composables/getDocument'

export default {
    props: ['clinicId'],
    setup(props) {
        const { user } = getUser()
        const { error, document: clinic } = getDocument('clinics', props.clinicId)
        const userObject = ref({}); // Initialize userObject as a reactive reference
        const documentHelpers = ref(null);
        const isPendingSave = ref(false)
        const clinicName = ref('')
        const clinicAddress = ref('')
        const clinicSeats = ref('')

        const loadUserObject = async () => {
            userObject.value = await getUserCollection(user.value.uid);
            if (userObject.value) {
                documentHelpers.value = useDocument('user', userObject.value.id)
            }
            clinicName.value = clinic.value.name
            clinicAddress.value = clinic.value.address
            clinicSeats.value = clinic.value.seats
        };

        loadUserObject();

        return { user, userObject, isPendingSave, clinic, clinicName, clinicAddress, clinicSeats }
    }
}

</script>

<style scoped>
.left-align {
    text-align: left;
}
</style>