<template>
    <div class="list-width position-relative" @mouseenter="showIndicators" @mouseleave="hideIndicators">
        <div class="overflow-x-scroll overflow-y-hidden" ref="scrollContainer" @scroll="handleScroll">
            <v-row class="flex-nowrap">
                <v-col v-for="col in item.columns" :key="col" class="text-left flex-grow-0 flex-shrink-0"
                    style="min-width: 300px;">
                    {{ col }}
                </v-col>
            </v-row>
            <v-row v-for="(rows, i) in item.response" :key="i" class="flex-nowrap">
                <v-col v-for="(row, j) in rows" :key="j" class="flex-grow-0 flex-shrink-0" style="min-width: 300px;">
                    <v-textarea flat auto-grow placeholder="" rows="1" variant="outlined" :disabled="preview"
                        class="text-area full-width margin-sides" v-model="item.response[i][j]"></v-textarea>
                </v-col>
            </v-row>
        </div>

        <div v-show="showLeftIndicator && isHovering" @click="scrollLeft" class="scroll-indicator left-indicator">
            <v-icon>mdi-chevron-left</v-icon>
        </div>

        <div v-show="showRightIndicator && isHovering" @click="scrollRight" class="scroll-indicator right-indicator">
            <v-icon>mdi-chevron-right</v-icon>
        </div>


        <v-btn class="mt-2 add-btn" @click="addRow" :elevation="0" v-show="!preview">
            Add
        </v-btn>
        <v-btn class="mt-2 add-btn" v-if="item.response.length > 1" @click="removeRow" :elevation="0" v-show="!preview">
            remove
        </v-btn>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, nextTick } from 'vue'

const props = defineProps({
    item: Object,
    preview: Boolean
})

const scrollContainer = ref(null);
const showLeftIndicator = ref(false);
const showRightIndicator = ref(true);
const isHovering = ref(false);

onMounted(() => {
    nextTick(() => {
        handleScroll({ target: scrollContainer.value });
    })
})

const handleScroll = (event) => {
    const container = event.target
    showLeftIndicator.value = container.scrollLeft > 0;
    showRightIndicator.value = container.scrollLeft < (container.scrollWidth - container.clientWidth);
}

const scrollLeft = () => {
    if (scrollContainer.value) {
        scrollContainer.value.scrollBy({ left: -300, behavior: 'smooth' });
    }
}

const scrollRight = () => {
    if (scrollContainer.value) {
        scrollContainer.value.scrollBy({ left: 300, behavior: 'smooth' });
    }
}

const showIndicators = () => {
    isHovering.value = true;
}

const hideIndicators = () => {
    isHovering.value = false;
}

const addRow = () => {
    props.item.response.push(props.item.columns.map(() => ''))
}

const removeRow = () => {
    if (props.item.response.length > 1) {
        props.item.response.pop()
    }
}

</script>

<style scoped>
.position-relative {
    position: relative;
}

.scroll-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    background: rgba(240, 240, 240, 0.657);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.scroll-indicator:hover {
    opacity: 1;
}

.left-indicator {
    left: 0;
}

.right-indicator {
    right: 0;

}

.list-width {
    width: 100%;
}

.required-field {
    color: var(--bittersweet);
}

.left-label ::v-deep .v-label {
    text-align: left;
}

.text-left {
    text-align: left;
}

.add-btn {
    align-self: end;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 7px;

}

/* the below is not supported in mozilla */

.position-relative:hover ::-webkit-scrollbar-thumb {
    background: rgba(102, 101, 101, 0.657);
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
