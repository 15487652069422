<template>
    <p class="mb-0 ml-1 px-1 editable" contenteditable @input="update" v-text="text" @keydown="handleKeydown"></p>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    id: String,
    text: String
})

const emit = defineEmits(['update:modelValue'])

const update = (e) => {
    emit('update:modelValue', e.target.textContent)
}

const handleKeydown = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        // e.target.blur();
    }
}
</script>

<style scoped>
.editable{
    /* text-decoration-line: line-through; */
}
</style>


