<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="8">
        <v-card class="d-flex flex-row" outlined>
          <!-- Image Column - Hide on small screens -->
          <v-col cols="12" md="6" class="pa-0 hidden-sm-and-down">
            <v-img :src="require('@/assets/mdhub-refer.jpg')" cover class="d-flex align-end"></v-img>
          </v-col>

          <!-- Content Column -->
          <v-col cols="12" md="6" class="d-flex flex-column">
            <v-card-title class="text-h5 text-center">
              Connect. Collaborate. Transform Care.
            </v-card-title>
            <v-card-subtitle class="pb-4 mt-5 text-center">
              Invite your colleagues to join our platform and collaborate on providing comprehensive care.
              Together, we can make a difference.
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="text-center my-3">
              <div>Invite a friend and they'll get <strong>one month free </strong> of mdhub Pro. Once they
                upgrade to a paid subscription, you'll receive a <strong>one-month subscription </strong> on
                us!</div>
            </v-card-text>
            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Promo Code" readonly v-model="referralCode" append-inner-icon="mdi-content-copy"
                    variant="underlined" @click:append-inner="copyToClipboard(referralCode)"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Referral Link" readonly v-model="referralUrl"
                    append-inner-icon="mdi-content-copy" variant="underlined"
                    @click:append-inner="copyToClipboard(referralUrl)"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer> <!-- This pushes the icons to the right -->

              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn icon @click="shareOnTwitter">
                    <v-icon color="blue">mdi-twitter</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn icon @click="shareOnFacebook">
                    <v-icon color="blue darken-2">mdi-facebook</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn icon @click="shareViaEmail">
                    <v-icon color="grey">mdi-email</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>




<script>

import getUser from '@/composables/getUser'
import { getUserCollection } from '@/composables/userService';
import { ref } from 'vue';
import { trackEvent, COPY_REFERAL } from '@/utilities/analyticsService';
import Feedback from '@/components/Feedback.vue';

export default {
  name: 'ReferralView',
  setup() {
    const { user } = getUser()
    const userObject = ref({}); // Initialize userObject as a reactive reference        
    const isPendingSave = ref(false)
    const referralUrl = ref()
    const referralCode = ref('');

    const loadUserObject = async () => {
      userObject.value = await getUserCollection(user.value.uid);
      if (userObject.value && userObject.value.referralCode == undefined) {
        console.log("Lets create one")
      } else {
        referralUrl.value = `https://app.mdhub.ai/signup?code=${userObject.value.referralCode}`
        referralCode.value = userObject.value.referralCode;
      }
    };

    const isLink = (text) => {
      // Basic URL validation regex pattern
      const urlPattern = /^(https?:\/\/)/;
      return urlPattern.test(text);
    }
    const copyToClipboard = (text) => {
      // Logic to copy text to clipboard
      if (isLink(text)) {
        trackEvent(COPY_REFERAL, { userId: user.value.uid, userEmail: userObject.value.email, copy: 'link' })
      } else {
        trackEvent(COPY_REFERAL, { userId: user.value.uid, userEmail: userObject.value.email, copy: 'code' })
      }

      navigator.clipboard.writeText(text).then(() => {

      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    }

    const shareViaEmail = () => {
      const subject = encodeURIComponent("Boost Your Practice with mdhub's AI Copilot");
      const body = encodeURIComponent("Hi,\n\n" +
        "Discover mdhub, an AI copilot transforming clinical documentation. Create clinical notes and patient handouts in seconds, improving care and saving time.\n\n" +
        "I'm inviting you to experience how mdhub can enhance your practice with my referral link. Let's move healthcare forward together.\n\n" +
        `${referralUrl.value}\n\n` +
        "Best regards\n");

      const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
      window.location.href = mailtoUrl;
    };

    const shareOnTwitter = () => {
      const text = encodeURIComponent("Discover mdhub, an AI copilot transforming clinical documentation. Create clinical notes and patient handouts in seconds, improving care and saving time.\n\n" +
        "I'm inviting you to experience how mdhub can enhance your practice with my referral link.\n\n" +
        `${referralUrl.value}`);
      const twitterUrl = `https://twitter.com/intent/tweet?text=${text}`;
      window.open(twitterUrl, '_blank');
    };

    const shareOnFacebook = () => {
      const url = encodeURIComponent(referralUrl.value);
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      window.open(facebookUrl, '_blank');
    };

    loadUserObject();

    return {
      user, userObject, isPendingSave, referralUrl, copyToClipboard, shareViaEmail, shareOnTwitter, shareOnFacebook, referralCode
    }
  }
};
</script>

<style scoped>
.v-card-subtitle {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 4.5em;
}

.v-card-title {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 4.5em;
}

@media (max-width: 600px) {
  .v-card-title {
    font-size: 18px;
  }
}

.d-flex.flex-row {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

/* Make the image column a flex container to make its child fill the height */
.pa-0.hidden-sm-and-down {
  display: flex;
  flex-direction: column;
}

.v-img {
  flex-grow: 1;
}
</style>
