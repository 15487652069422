<template>
    <div v-if="dbAudio && session?.id !== sessionId" class="py-5 d-flex flex-column align-center">
        <p class="text-caption px-10">Your audio file failed to upload. <span class="font-weight-bold"> Click Trigger Upload </span>for mdhub to
            automatically recover audio.</p>
        <v-btn class="mdhub-btn" :loading="uploading" elevation="0" @click="syncAudio">Trigger upload</v-btn>

        <v-snackbar v-model="snackbar" :timeout="2000" color="bittersweet">
            <span style="color:white;">{{ snackbarText }}</span>
        </v-snackbar>
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import { get, deleteById } from '@/composables/useAudioDB'
import getUser from '@/composables/getUser';
import uploadAudio from '@/composables/useStorage';
import fixWebmDuration from "fix-webm-duration";
import { trackEvent, AUDIO_LOCAL_GET, AUDIO_LOCAL_SYNC, AUDIO_LOCAL_SYNC_ERROR } from '@/utilities/analyticsService';
import { useRecorderStore } from '@/store/recorder'
import { storeToRefs } from 'pinia'

const props = defineProps({
    session: String,
});

const { user } = getUser();

const store = useRecorderStore();
const { sessionId } = storeToRefs(store)

const dbAudio = ref(null);
onMounted(async () => {
    try {
        const audioData = await get(props.session.id);
        dbAudio.value = audioData;
        trackEvent(AUDIO_LOCAL_GET, { userId: user.value.uid, userEmail: user.value.email, sessionId: props.session.id, patientId: props.session.patientId });
    } catch (error) {
        console.error(error);
    }
})

const uploading = ref(false);
const snackbar = ref(false);
const snackbarText = ref('')
const syncAudio = async () => {
    try {
        uploading.value = true;

        const blob = dbAudio.value.audio
        const duration = dbAudio.value.updatedAt - props.session.createdAt.toDate()
        const fixedBlob = await fixWebmDuration(blob, duration, { logger: false });

        await uploadAudio(props.session.patientId, props.session.id, fixedBlob, user.value.uid, user.value.email)
        trackEvent(AUDIO_LOCAL_SYNC, { userId: user.value.uid, userEmail: user.value.email, sessionId: props.session.id, patientId: props.session.patientId });
        await deleteById(props.session.id);
    } catch (error) {
        snackbarText.value = 'Error uploading audio';
        snackbar.value = true;
        trackEvent(AUDIO_LOCAL_SYNC_ERROR, { userId: user.value.uid, userEmail: user.value.email, sessionId: props.session.id, patientId: props.session.patientId });
        console.error(error);
    } finally {
        uploading.value = false;
        snackbarText.value = 'Audio uploaded';
        snackbar.value = true;
    }
}

</script>