<template>
    <div class="d-flex justify-center container">
        <div class="d-flex justify-center" v-if="gettingPlan">
            <v-progress-circular class="mt-12 mx-auto" color="primary" indeterminate></v-progress-circular>
        </div>
        <v-card class="rounded-card myCard d-flex flex-column pb-10" elevation="0" v-else-if="!!treatmentPlan?.plan">
            <div class="d-flex flex-column head">
                <v-progress-linear v-if="updatingPlan" color="var(--melon)" style="width:100%;"
                    indeterminate></v-progress-linear>

                <div class="d-flex align-center">
                    <p class="text-left px-10 pt-5 text-h5 font-weight-bold">Treatment Plan for {{
                        treatmentPlan?.patientName }} </p>

                    <TreatmentPlanCopyButton :treatmentPlan="treatmentPlan" />
                </div>

            </div>
            <div class="text-left px-10" :class="goalIndex === 0 ? 'mt-5' : ''"
                v-for="(goal, goalIndex) in treatmentPlan?.plan?.plan?.goals" :key="goalIndex">
                <div class="d-flex align-start" :id="`goal_header_${goalIndex}`">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <p class="mb-0 font-weight-bold flex-shrink-0 editable" v-bind="props">Goal {{ goalIndex + 1
                                }}:</p>
                        </template>
                        <v-list>
                            <v-list-item @click="addObjective(goalIndex)">
                                <v-list-item-subtitle>Add new objective</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item @click="addGoal(goalIndex)">
                                <v-list-item-subtitle>Add new goal</v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item @click="treatmentPlan?.plan?.plan?.goals.splice(goalIndex, 1)">
                                <v-list-item-subtitle>Delete this
                                    goal</v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <TreatmentPlanEditableText v-model="goal.description" :text="goal.description" />
                </div>

                <div class="ml-3">

                    <div v-for="(objective, objectiveIndex) in goal.objectives" :key="objectiveIndex">
                        <div class="d-flex align-start" :id="`goal_header_${goalIndex}_objective_${objectiveIndex}`">

                            <TreatmentPlanDeleteDropdown :array="goal.objectives" :index="objectiveIndex"
                                titleText="Objective" subtitleText="objective" :bold="true" />

                            <TreatmentPlanEditableText v-model="objective.specific" :text="objective.specific" />
                        </div>

                        <div class="ml-3">
                            <div class="d-flex align-center">
                                <TreatmentPlanAddDropdown :array="objective.metrics" titleText="Metrics"
                                    subtitleText="assessment tool" />
                            </div>

                            <div v-for="(metric, metricIndex) in objective.metrics" class="ml-3" :key="metricIndex">
                                <div class="d-flex align-start">
                                    <TreatmentPlanDeleteDropdown :array="objective.metrics" :index="metricIndex"
                                        titleText="Assessment tools" subtitleText="assessment tool" />
                                    <TreatmentPlanEditableText v-model="objective.metrics[metricIndex]"
                                        :text="metric" />
                                </div>
                            </div>

                            <p class="mb-0 font-weight-bold">Attainability:</p>
                            <TreatmentPlanEditableText v-model="objective.attainability"
                                :text="objective.attainability" />

                            <p class="mb-0 font-weight-bold">Relevance:</p>
                            <TreatmentPlanEditableText v-model="objective.relevance" :text="objective.relevance" />

                            <p class="mb-0 font-weight-bold">Timeframe:</p>
                            <TreatmentPlanEditableText v-model="objective.timeframe" :text="objective.timeframe" />

                            <div class="d-flex align-center">
                                <TreatmentPlanAddDropdown :array="objective.interventions" titleText="Interventions"
                                    subtitleText="intervention" />
                            </div>
                            <div v-for="(intervention, interventionIndex) in objective.interventions" class="ml-3"
                                :key="interventionIndex">
                                <div class="d-flex align-start">
                                    <TreatmentPlanDeleteDropdown :array="objective.interventions"
                                        :index="interventionIndex" titleText="Intervention"
                                        subtitleText="intervention" />
                                    <TreatmentPlanEditableText v-model="objective.interventions[interventionIndex]"
                                        :text="intervention" />
                                </div>
                            </div>

                            <div class="d-flex align-center">
                                <TreatmentPlanAddDropdown :array="objective.homework" titleText="Homework"
                                    subtitleText="homework" />
                            </div>
                            <div v-for="(homework, homeworkIndex) in objective.homework" class="ml-3"
                                :key="homeworkIndex">
                                <div class="d-flex align-start">
                                    <TreatmentPlanDeleteDropdown :array="objective.homework" :index="homeworkIndex"
                                        titleText="Homework" subtitleText="homework" />
                                    <TreatmentPlanEditableText v-model="objective.homework[homeworkIndex]"
                                        :text="homework" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr v-if="goalIndex < treatmentPlan?.plan?.plan?.goals?.length - 1">
            </div>
        </v-card>
        <div v-else>
            <p>Error fetching treatment plan</p>
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import TreatmentPlanEditableText from '@/components/treatment-plan/TreatmentPlanEditableText.vue';
import TreatmentPlanDeleteDropdown from '@/components/treatment-plan/TreatmentPlanDeleteDropdown.vue';
import TreatmentPlanAddDropdown from '@/components/treatment-plan/TreatmentPlanAddDropdown.vue';
import TreatmentPlanCopyButton from '@/components/treatment-plan/TreatmentPlanCopyButton.vue';
import { watchDebounced } from '@vueuse/core';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, onMounted, nextTick } from 'vue'
import { trackEvent, TREATMENT_PLAN_UPDATE, TREATMENT_PLAN_UPDATE_ERROR } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'

const { user } = getUser()
const route = useRoute()
const functions = getFunctions()

const patientId = route.params.patientId

const gettingPlan = ref(false)
const treatmentPlan = ref(null)
onMounted(async () => {
    gettingPlan.value = true
    const getTreatmentPlan = httpsCallable(functions, 'getTreatmentPlan')
    try {
        const result = await getTreatmentPlan({ patientId })
        console.log(result)
        treatmentPlan.value = result.data
    } catch (err) {
        console.log(err)
    } finally {
        gettingPlan.value = false
    }
})

watchDebounced(treatmentPlan, () => {
    updatePlan(patientId, treatmentPlan?.value?.plan?.plan)
}, { deep: true, debounce: 1000, maxWait: 5000 })


const updatingPlan = ref(false)
const updatePlan = async (patientId, plan) => {
    updatingPlan.value = true
    const updateTreatmentPlan = httpsCallable(functions, 'updateTreatmentPlan');
    try {
        await updateTreatmentPlan({ patientId, plan })
        trackEvent(TREATMENT_PLAN_UPDATE, { userId: user.value.uid, userEmail: user.value.email, patientId });
    } catch (err) {
        console.log(err)
        trackEvent(TREATMENT_PLAN_UPDATE_ERROR, { userId: user.value.uid, userEmail: user.value.email, patientId, error: { message: err.message, name: err.name, stack: err.stack } });
    }
    finally {
        updatingPlan.value = false
    }
}

const addGoal = (index) => {
    treatmentPlan.value.plan.plan.goals.splice(
        index + 1, 0,
        {
            description: 'Goal',
            objectives: [
                {
                    attainability: 'Attainability',
                    homework: [
                        'Homework'
                    ],
                    interventions: [
                        'Intervention'
                    ],
                    metrics: [
                        'Assessment tool'
                    ],
                    relevance: 'Relevance',
                    specific: 'Objective',
                    timeframe: 'Timeframe'
                }
            ]
        }
    )

    scrollToItem(`goal_header_${index + 1}`)
}

const addObjective = (index) => {
    treatmentPlan.value.plan.plan.goals[index].objectives.push(
        {
            attainability: 'Attainability',
            homework: [
                'Homework'
            ],
            interventions: [
                'Intervention'
            ],
            metrics: [
                'Assessment tool'
            ],
            relevance: 'Relevance',
            specific: 'Objective',
            timeframe: 'Timeframe'
        }
    )

    scrollToItem(`goal_header_${index}_objective_${treatmentPlan.value.plan.plan.goals[index].objectives.length - 1}`)
}

const scrollToItem = (id) => {
    nextTick(() => {
        const element = document.getElementById(id)
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        })
    })
}
</script>

<style scoped>
.myCard {
    max-height: 85vh;
    overflow-y: scroll;
}

.head {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

::v-deep .editable {
    cursor: pointer;
    outline-color: var(--melon);
}

::v-deep .editable:hover {
    /* text-decoration: underline; */
    background-color: aliceblue;
}
</style>