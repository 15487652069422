<template>
    <v-sheet class="d-flex flex-column justify-center align-center mt-4" color="#f9fbfd">
        <v-card class="mdhub-card elevation-0" width="100%" height="100%">
            <v-card-title class="left-align mt-7 ml-6">Import patients</v-card-title>
            <v-card-subtitle class="left-align mb-5 ml-6">Imported patients will be visible to all the clinic team
                members.</v-card-subtitle>
            <div class="left-align mb-5 mt-7 ml-6">
                <v-file-input label="Upload CSV File" accept=".csv" @change="handleFileUpload" class="ml-4 mr-4 mt-4"
                    color="accent" variant="underlined" />
            </div>
            <v-progress-circular v-if="isPendingSave" indeterminate color="primary"></v-progress-circular>
            <div v-if="totalPatients > 0" class="notification mb-1">
                <p>{{ totalPatients }} patients found.</p>
                <p>{{ createdPatients }} out of {{ totalPatients }} patients created.</p>
                <p v-if="failedPatients > 0">{{ failedPatients }} patient(s) failed to be created.</p>
                <ul v-if="errorMessages.length > 0">
                    <p v-for="(error, index) in errorMessages" :key="index">
                        {{ error }}
                    </p>
                </ul>
            </div>
        </v-card>
    </v-sheet>
</template>

<script>
import { ref } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Papa from 'papaparse';
import getUser from '@/composables/getUser';
import getDocument from '@/composables/getDocument';
import { Timestamp } from "@firebase/firestore";

export default {
    props: ['clinicId'],
    setup(props) {
        const { user } = getUser();
        const { error, document: clinic } = getDocument('clinics', props.clinicId);
        const isPendingSave = ref(false);
        const totalPatients = ref(0);
        const createdPatients = ref(0);
        const failedPatients = ref(0); // Add a ref for the number of failed patients
        const errorMessages = ref([]); // Add a ref for error messages
        const auth = getAuth();

        // Define the getPronoun function
        function getPronoun(gender) {
            if (!gender) return '';
            switch (gender.toLowerCase()) {
                case 'male':
                    return 'he/him';
                case 'female':
                    return 'she/her';
                case 'non-binary':
                    return 'they/them';
                case 'prefer not to say':
                    return '';
                case 'other':
                    return '';
                default:
                    return 'he/him';
            }
        }

        const createPatient = async (patientData) => {
            const functions = getFunctions();
            const createPatientAdmin = httpsCallable(functions, 'createPatientAdmin');

            let dobTimestamp;
            if (patientData.dob) {
                let dobDate = new Date(patientData.dob);
                const dateNow = new Date();

                dobDate.setUTCHours(dateNow.getUTCHours());
                dobDate.setUTCMinutes(dateNow.getUTCMinutes());
                dobDate.setUTCSeconds(dateNow.getUTCSeconds());

                dobTimestamp = Timestamp.fromDate(dobDate);
            } else if (patientData.age) {
                const currentYear = new Date().getFullYear();
                const yearOfBirth = currentYear - patientData.age;
                const dob = new Date(yearOfBirth, 6, 2); // July 2 of yearOfBirth
                dobTimestamp = Timestamp.fromDate(dob);
            }

            // If email is missing, assign an empty string
            const email = patientData.email || '';

            const pronoun = getPronoun(patientData.gender);

            // Handle patientName, first_name, and last_name logic
            let firstName = '';
            let lastName = '';
            let fullName = '';

            if (patientData.first_name && patientData.last_name) {
                firstName = patientData.first_name;
                lastName = patientData.last_name;
                fullName = `${firstName} ${lastName}`; // Concatenate first_name and last_name
            } else {
                fullName = patientData.name || ''; // Use name if first_name and last_name are not provided
            }

            try {
                let res = await createPatientAdmin({
                    patientName: fullName, // Send the concatenated fullName or name
                    patientFirstName: firstName, // Send first_name, empty string if not available
                    patientLastName: lastName, // Send last_name, empty string if not available
                    userName: user.value.displayName,
                    pronoun: pronoun,
                    patientDescription: "",
                    address: "",
                    diagnosis: '',
                    patientUrl: '',
                    sessions: [],
                    gender: patientData.gender,
                    dob: dobTimestamp.toDate(),
                    email: email // Add email to the patient creation payload
                });
                console.log('Patient created successfully:', res);
                createdPatients.value++;
            } catch (error) {
                console.error('Error creating patient:', error);
                let message = `Patient ${fullName} could not be created.`;
                if (error.code === 'already-exists') {
                    message = `Patient ${fullName} already exists.`;
                } else if (error.message) {
                    message = `Patient ${fullName} could not be created: ${error.message}`;
                }
                errorMessages.value.push(message); // Add the error message to the list
                failedPatients.value++; // Increment the count of failed patients
            }
        };


        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        isPendingSave.value = true;
                        Papa.parse(file, {
                            header: true,
                            complete: async (results) => {
                                const patients = results.data;
                                totalPatients.value = patients.length;
                                createdPatients.value = 0;
                                failedPatients.value = 0; // Reset the count of failed patients
                                errorMessages.value = []; // Clear previous error messages
                                for (const patient of patients) {
                                    console.log('patient', patient)
                                    if ((patient.name || (patient.first_name && patient.last_name)) && patient.gender && (patient.dob || patient.age)) {
                                        await createPatient(patient);
                                    } else {
                                        console.error('Missing required patient data:', patient);
                                        errorMessages.value.push(`Patient ${patient.name} could not be created due to missing data.`);
                                        failedPatients.value++; // Increment the count of failed patients
                                    }
                                }
                                isPendingSave.value = false;
                            },
                            error: (error) => {
                                console.error('Error parsing CSV file:', error);
                                isPendingSave.value = false;
                            }
                        });
                    } else {
                        console.error('User not authenticated');
                    }
                });
            }
        };

        return { user, isPendingSave, totalPatients, createdPatients, failedPatients, errorMessages, clinic, handleFileUpload };
    }
};
</script>

<style scoped>
.left-align {
    text-align: left;
}

.notification {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #ebedef;
}
</style>