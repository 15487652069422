import { LiveTranscriptionEvents, createClient } from "@deepgram/sdk";
import { onMounted, onUnmounted, ref } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from '@/firebase/config'
import { isWhiteListedAccount } from '@/composables/isWhitelistedAccount'

const deepgramConnection = ref(null);
const connectionOptions = ref({
    model: "nova-2",
    smart_format: true,
    language: 'multi',
    diarize: true,
    punctuate: true,

});

const canUseLiveTranscript = isWhiteListedAccount();
const formattedTranscribedText = ref('');
const liveTranscript = ref(canUseLiveTranscript ? 'Preparing live transcript...' : null);
const liveStreamReady = ref(false);
const liveTranscriptUnformatted = ref('')
const userRef = ref(null);
const sessionRef = ref(null);
const isOnline = ref(navigator.onLine);
const functions = getFunctions();
const updateSessionData = httpsCallable(functions, 'updateSessionData');

const initializeLiveTranscription = async (sessionData) => {

    // restrict it only to specific users
    if (!canUseLiveTranscript) return;

    // get config
    const { userId, sessionId } = sessionData;
    userRef.value = userId;
    sessionRef.value = sessionId;
    // here we need to pass the correct api key   
    console.log('Initializing live transcription')
    const deepgram = createClient(firebaseConfig.deepgramApiKey);
    deepgramConnection.value = deepgram.listen.live(connectionOptions.value);

    let keepAlive;
    let saveLiveTranscriptInterval;

    if (keepAlive) clearInterval(keepAlive);
    if (saveLiveTranscriptInterval) clearInterval(saveLiveTranscriptInterval);

    keepAlive = setInterval(() => {
        console.log("KeepAlive sent.");
        //keep alive the connection otherwise it will automatically close by deepgram after 12 seconds of silence
        deepgramConnection.value.keepAlive();

    }, 10 * 1000);

    // "throttle" the storing every 10 seconds we can adjust it according to our needs. I think this is needed in order to avoid 
    // overwhelming the db and the functions. We could think about storing it directly to the db as well. 
    saveLiveTranscriptInterval = setInterval(async () => {
        try {
            const updateData = { liveTranscript: liveTranscriptUnformatted.value };
            await updateSessionData({ userId: userId, sessionId: sessionId, updateData });
            console.log('Live transcript saved.')
        } catch (error) {
            console.log('Error while trying to save live transcript', error)
        }
    }, 10000)

    deepgramConnection.value.on(LiveTranscriptionEvents.Open, () => {
        liveStreamReady.value = deepgramConnection.value?.getReadyState() === 1;
        console.log('isDeepgramReady', liveStreamReady.value)

        // have in mind to close it
        deepgramConnection.value.on(LiveTranscriptionEvents.Transcript, (data) => {
            console.log('LiveTranscriptionEvents.Transcript:', data.channel.alternatives[0].transcript);
            const transcript = data.channel.alternatives[0].transcript;
            const isEndOfLine = transcript.match(/[.!?]$/);
            liveTranscriptUnformatted.value += " " + transcript;
            formattedTranscribedText.value += isEndOfLine ? `<div>${transcript}</div><br>` : ` <span>${transcript}</span>`;
            liveTranscript.value = formattedTranscribedText.value;
        });
        deepgramConnection.value.on(LiveTranscriptionEvents.Error, (data) => {
            console.log('LiveTranscriptionEvents.Error:', data);
        });
        deepgramConnection.value.on(LiveTranscriptionEvents.Warning, (data) => {
            console.log('LiveTranscriptionEvents.Warning:', data);
        });
        deepgramConnection.value.on(LiveTranscriptionEvents.Close, async () => {
            console.log("Connection closed.");
            console.log('Whole transcript:', liveTranscript.value)
            clearInterval(keepAlive);
            clearInterval(saveLiveTranscriptInterval);

            // to be sure that we saved the last 10 seconds 
            if (isOnline.value) {
                const updateData = { liveTranscript: liveTranscriptUnformatted.value };
                await updateSessionData({ userId: userId, sessionId: sessionId, updateData });
                resetLiveStreamTranscript();
            }

        });
    });

}

const handleConnectivity = async () => {
    isOnline.value = navigator.onLine;
    // >=2 means a not ready state. 
    // We keep it open and when it's back online it will try to establish a new connection again. 
    // Also that way it continues getting the blobs in order to create the live transcript when it's back online.
    if (deepgramConnection.value?.getReadyState() >= 2) {
        console.log('went offline try to finish the connection')
        deepgramConnection.value.finish();
        deepgramConnection.value.removeAllListeners();
        await initializeLiveTranscription(
            {
                userId: userRef.value,
                sessionId: sessionRef.value
            }
        )
    }

}

const handleLiveStream = (blob) => {
    if (deepgramConnection.value && liveStreamReady.value) {
        deepgramConnection.value.send(blob);
    }
}

const resetLiveStreamTranscript = () => {
    formattedTranscribedText.value = '';
    liveTranscriptUnformatted.value = '';
    liveTranscript.value = 'Preparing live transcript...'
}

const finishLiveTranscript = () => {
    if (deepgramConnection.value) {
        deepgramConnection.value.finish();
    }
}

const useLiveTranscript = () => {
    resetLiveStreamTranscript();
    onMounted(() => {
        // trying to handle the connectivity issue - not working very well due to dg closing the connection. 
        window.addEventListener('online', handleConnectivity);
        window.addEventListener('offline', handleConnectivity);
    });

    onUnmounted(() => {
        window.removeEventListener('online', handleConnectivity);
        window.removeEventListener('offline', handleConnectivity);
        finishLiveTranscript()
    });
    return { initializeLiveTranscription, finishLiveTranscript, liveTranscript, handleLiveStream, liveStreamReady }
}

export default useLiveTranscript

